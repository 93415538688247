import { useEffect, useState } from "react"
import API from "../../common/API"
import {
  Activities,
  Runner,
  RunnerTask,
} from "../../interfaces/runnerTask.interface"
import { Runs } from "../../interfaces/runs.interface"
import { filter } from "lodash"
import { FilterObject } from "../../interfaces/filterObj.interface"
import { Toast } from "react-bootstrap"
import { ToastNotificationSuccess } from "../Common/Notifications/ToastNotification"
import Swal from "sweetalert2"

interface Props {
  runs: Runs[]
  filterObj: FilterObject
}
const RunnersListView = ({ runs, filterObj }: Props) => {
  const [task, setTask] = useState<Activities[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const getTask = async (runId: string, runner: Runner) => {
    try {
      const response = await API.getAction(`RunActivity/List?RunId=${runId}`)
      const newTasks = response.data.response.sort((a, b) => a.order - b.order)

      return newTasks.map((task: RunnerTask) => {
        return {
          ...task,
          runnerId: runner.id,
          runnerName: runner.firstName + " " + runner.lastName,
          runnerEmail: runner.email,
          runnerMobile: runner.mobilePhone,
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getActivities = async () => {
    try {
      setIsLoading(true)
      const tasks = await Promise.all(
        runs.map(async run => {
          const tasks = await getTask(run.id, run.runner)
          return tasks
        })
      )
      const mergedArray = tasks.flat()
      setTask(
        mergedArray
          .sort((a, b) => a.runnerName.localeCompare(b.runnerName))
          .filter(task => {
            const [firstName, lastName] = task.runnerName.split(" ")

            const matchesFirstName =
              !filterObj.firstName || firstName === filterObj.firstName
            const matchesLastName =
              !filterObj.lastName || lastName === filterObj.lastName
            const matchesContactNumber =
              !filterObj.contactNumber ||
              task.runnerMobile === filterObj.contactNumber
            const matchesEmail =
              !filterObj.email || task.runnerEmail === filterObj.email

            return (
              matchesFirstName &&
              matchesLastName &&
              matchesContactNumber &&
              matchesEmail
            )
          })
      )
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getActivities()
  }, [runs, filterObj])

  const handleMarkAsDone = async (task: Activities) => {
    try {
      const updatedTask = {
        activity: task.activity,
        id: task.id,
        runId: task.runId,
        note: task.note,
        done: !task.done ? new Date().toISOString() : null,
        attachments: task.attachments || [],
        created: task.created || new Date().toISOString(),
        order: task.order || 0,
      }

      await API.postAction("RunActivity/AddEdit", updatedTask)

      setTask(prev => {
        const updatedTasks = prev.map(prevTask => {
          if (prevTask.id === task.id) {
            return { ...prevTask, done: !prevTask.done }
          }
          return prevTask
        })
        return updatedTasks as Activities[]
      })

      Swal.fire({
        title: "Success",
        text: `Task has been marked as ${
          !task.done ? "Done" : "Pending"
        } successfully`,
        icon: "success",
        confirmButtonText: "Ok",
      })
    } catch (e) {
      console.log(e)
    }
  }

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <table className="table table-bordered w-100 bg-white rounded-xl">
      <thead className="thead-light align-middle">
        <tr>
          <th className="col-2">Activity</th>
          <th className="col-5">Note</th>
          <th>Runner Name</th>
          <th>Runner Mobile</th>
          <th className="text-center">Status</th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {task.map(activity => (
          <tr key={activity.id}>
            <td
              className={`overflow-hidden text-truncate p-0 align-middle justify-content-center align-items-center`}
              style={{ maxWidth: "150px" }}
            >
              <div
                className={`${
                  activity.done ? "completed" : "pending"
                } w-100  p-2 d-inline-block align-middle`}
                style={{
                  minHeight: "60px",

                  alignItems: "center",
                }}
              >
                {activity.activity}
              </div>
            </td>
            <td
              className="text-truncate align-middle"
              style={{ maxWidth: "300px" }}
            >
              {activity.note || "N/A"}
            </td>
            <td className="text-truncate align-middle">
              {activity.runnerName}
            </td>
            <td className="text-truncate align-middle">
              {activity.runnerMobile}
            </td>
            <td className="text-center align-middle">
              {activity.done ? "Done" : "Pending"}
            </td>
            <td className="text-center align-middle">
              <button
                className={`text-white btn ${
                  activity.done ? "btn-secondary" : "btn-primary"
                } `}
                style={{ minWidth: "150px", fontWeight: "bold" }}
                onClick={() => handleMarkAsDone(activity)}
              >
                Mark as {activity.done ? "Pending" : "Done"}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default RunnersListView
