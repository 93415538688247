
import React from "react";
import Login from "../components/Profile/Login";



const LoginPage = () => {
    return (<Login />)
}

export default LoginPage;