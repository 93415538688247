import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons"

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "react-bootstrap"
import { Link } from "react-router-dom"
import API from "../../common/API"
import { Routes } from "../../routes"
import BgImage from "../../assets/img/illustrations/signin.svg"
import ClearUser from "../../common/ClearUser"

//translation
import { useTranslation } from "react-i18next"

const ForgotPassword = props => {
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation("global")
  const [state, setState] = useState({
    Email: "",
    UI: {
      validated: false,
    },
  })

  useEffect(() => {
    ClearUser()
  }, [])

  const handleSubmit = async event => {
    event.preventDefault()
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      console.log("Continue to next process", state)

      try {
        setIsLoading(true)

        let request = await API.getAction(
          "account/ForgotPassword?email=" + state.Email
        )
        setIsLoading(false)
        alert(t("alerts.request_received"))
        let response = request.data
        props.history.push(Routes.SignIn.path)
      } catch (err) {
        console.log("Error", err.message)
        debugger
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <div className="text-center my-4">
            <img
              src="/icons/64x64-icon.png"
              alt="plnify-runners"
              className="navbar-brand-light "
            />
          </div>

          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">{t("recover_password")}</h4>
                </div>
                <Form
                  className="mt-4 shadow-sm"
                  noValidate
                  validated={state.UI.validated}
                  onSubmit={handleSubmit}
                >
                  <Form.Group id="email" className="mb-4 ">
                    <Form.Label>{t("email")} </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        
                        value={state.Email}
                        onChange={e => {
                          setState({
                            ...state,
                            Email: e.target.value,
                          })
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("alerts.email_invalid")}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm mx-4"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    {t("recover_password")}
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    <Card.Link
                      as={Link}
                      to={Routes.SignIn.path}
                      className="fw-bold"
                    >
                      {t("login")}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default ForgotPassword
