import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row, Card, Container } from "react-bootstrap"
import { useParams } from "react-router"
import SettingSetions from "./SettingSections"
import UserComponent from "./UserComponent"
import RoleComponent from "./RoleComponent"
import CountryComponent from "./CountryComponent"
import EarTypesComponent from "./EarTypesComponent"
import EyeTypesComponent from "./EyeTypesComponent"
import HairTypesComponent from "./HairTypesComponent"
import HeightComponent from "./HeightComponent"
import MouthTypesComponent from "./MouthTypesComponent"
import NoseTypesComponent from "./NoseTypesComponent"
import NationalityComponent from "./NationalityComponent"


interface SettingsDTO {
  id: string
}
const Settings = () => {
  const { id } = useParams<SettingsDTO>()
  const [section, setSection] = useState("")
  const loadedSettings = SettingSetions

  const [t] = useTranslation("global")
  const toggle = (obj: string) => {
    setSection(obj)
  }
  return (
    <>
      {section === "users" && <UserComponent show={true} toggle={toggle} />}
      {section === "roles" && <RoleComponent show={true} toggle={toggle} />}
      {section === "countries" && <CountryComponent show={true} toggle={toggle} />}
      {section === "earTypes" && <EarTypesComponent show={true} toggle={toggle} />}
      {section === "eyeTypes" && <EyeTypesComponent show={true} toggle={toggle} />}
      {section === "hairType" && <HairTypesComponent show={true} toggle={toggle} />}
      {section === "height" && <HeightComponent show={true} toggle={toggle} />}
      {section === "mouthTypes" && <MouthTypesComponent show={true} toggle={toggle} />}
      {section === "noseType" && <NoseTypesComponent show={true} toggle={toggle} />}
      {section === "nationality" && <NationalityComponent show={true} toggle={toggle} />}
      
      <Container>
        <Row className="my-4">
          <Col xs={12}>
            <Row>
              {/* <Col xs={12} md={6}>
                <OrganizationBox />
              </Col>
              
              <Col xs={12} md={6} className="text-end">
              <CurrentSubscription/>
              </Col> */}
              <Col xs={12}>
                {Array.isArray(loadedSettings) &&
                  loadedSettings.map((mainSection, index) => {
                    if (
                      mainSection.requiredParent &&
                      mainSection.parentId !== id
                    )
                      return null
                    return (
                      <Card className="shadow-sm mb-2" key={index}>
                        <Card.Header>
                          <Card.Title>{t(mainSection.name)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ul className="nav nav-pills nav-fill  flex-column ">
                            {mainSection.sections.map(
                              (subSection, subSectionIndex) => {
                                return (
                                  <li
                                    className="nav-item "
                                    key={subSectionIndex}
                                  >
                                    <button
                                      className={
                                        " btn-block btn  text-left " +
                                        (section === subSection.reference
                                          ? "btn-primary"
                                          : "btn")
                                      }
                                      onClick={() => {
                                        toggle(subSection.reference)
                                      }}
                                    >
                                      {t(subSection.label)}
                                    </button>
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </Card.Body>
                      </Card>
                    )
                  })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Settings
