export const Routes = {
  // GENERIC
  getin: {
    path: "/getin/:id",
    clean: "/getin/",
  },

  AdminUsers: { path: "/admin/users" },
  AdminOrganizations: { path: "/admin/organizations" },
  SignIn: { path: "/signin" },
  ForgotPassword: { path: "/forgotPassword" },
  Register: { path: "/register" },
  Runners:{path:"/runners"},
  Home: { path: "/" },

  NotFound: { path: "/examples/NotFound" },
  ServerError: { path: "/examples/ServerError" },
  Chat: { path: "/Chat" },
  Settings: { path: "/Settings/:id?", clean: "/Settings/" },
  Reports: { path: "/reports/:id" },
  Profile: { path: "/profile" },
  Search: { path: "/search" },
  Employees: { path: "/employees" },

  SelectOrganization: { path: "/SelectOrganization" },
  RunnerView: {
    path: "/r/:runId",
  },
}
