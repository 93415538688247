import { Cog, DotsVertical } from "mdi-material-ui"
import { useState } from "react"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"

export const DropDownComponent = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const { data, accesor, direction, cog } = props

  return (
    <Dropdown
      className={props.className !== null ? props.className : null}
      direction={direction}
      isOpen={dropdownOpen}
      toggle={toggle}
      id="dropDown"
    >
      <DropdownToggle
        id="dropDown"
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {props.Custom ? (
          props.Custom
        ) : (
          <div className="dot-icon relative ml-auto" id="dropDown">
            {cog ? (
              <div className="btn btn-sm btn-primary">
                <Cog htmlColor={props.color ? props.color : "black"} />
              </div>
            ) : (
              <DotsVertical htmlColor={props.color ? props.color : "black"} />
            )}
          </div>
        )}
      </DropdownToggle>
      <DropdownMenu
        className={props.menuClass ? props.menuClass : null}
        style={{ background: "white", zIndex: "999", padding: "0" }}
      >
        {data.length
          ? data.map(value => {
              return (
                <div
                  className={`${value.border ? "border-bottom" : " "}  ${
                    value.delete ? "delete " : " "
                  } dropDownList ${value.className}`}
                  key={Math.random()}
                  id={value.name}
                  onClick={e => {
                    toggle()
                    value.trigger(e)
                  }}
                >
                  {accesor ? value[accesor] : value.name}
                </div>
              )
            })
          : null}
        {props.children}
      </DropdownMenu>
    </Dropdown>
  )
}
