const LogoComponent = () => {
  return (
    <div>
      <img src="/icons/100x100-icon.png" alt="Logo" width={80} />
      <h1
        className=""
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        Welcome to Plnify Runners
      </h1>
    </div>
  )
}

export default LogoComponent
