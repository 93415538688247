import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { SortableContext, arrayMove } from "@dnd-kit/sortable"
import { useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { ColumnContainer } from "./ColumnContainer"
import { TaskCard } from "./TaskCard"
import { Column, Id, Task } from "../../interfaces/column.interface"
import { generateId } from "../../helpers/generateId"
import plnifyLogo from "../../assets/img/plnifyLogoBlack.png"
import { Runs } from "../../interfaces/runs.interface"
import { TaskInterface } from "../../interfaces/task.interface"
import * as uuid from "uuid"
import Swal from "sweetalert2"
import API from "../../common/API"
import { set } from "lodash"
import SharingComponent from "../Common/SharingComponent"

interface RunnersBoardProps {
  isLoading: boolean
  showFilter?: boolean
  runs: Runs[]
  toggleAddRunModal: () => void
}

const RunnersBoard = ({
  isLoading,
  showFilter,
  runs,
  toggleAddRunModal,
}: RunnersBoardProps) => {
  const [TaskLoading, setTaskLoading] = useState(false)
  const [columns, setColumns] = useState<Runs[]>(runs)
  const [activeColumn, setActiveColumn] = useState<Runs | null>(null)
  const columnsId = useMemo(() => columns.map(col => col.id), [columns])
  const [currentColumn, setCurrentColumn] = useState<Runs | null>(null)

  const [tasks, setTasks] = useState<TaskInterface[]>([])
  const [activeTask, setActiveTask] = useState<TaskInterface | null>(null)

  const [showShareModal, setShowShareModal] = useState(false)

  const [taskEditMode, setTaskEditMode] = useState({
    id: "",
    isEditMode: false,
  })

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  )

  useEffect(() => {
    setColumns(runs)
  }, [runs])

  function createTask(runId: Id, title: string, content: string) {
    const payload: TaskInterface = {
      id: uuid.v4(),
      runId: runId as string,
      activity: title,
      note: content,
      done: null,
      attachments: [
        {
          id: null,
          runActivityId: null,
          name: null,
          path: null,
        },
      ],
      order: tasks.filter(task => task.runId === runId).length,
      isState: true,
      created: new Date().toISOString(),
    }

    setTasks([...tasks, payload])
  }

  function deleteTask(id: Id) {
    Swal.fire({
      title: "Are you sure, you want to delete this task?",
      text: "You won't be able to revert this!",
      imageUrl: "/icons/100x100-icon.png",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setTaskLoading(true)
          await API.getAction(`RunActivity/Delete?id=${id}`)
          const newTasks = tasks.filter(task => task.id !== id)
          setTasks(newTasks)
          Swal.fire("Deleted!", "Your task has been deleted.", "success")
        } catch (e) {
          console.log(e)
          Swal.fire("Error!", "Something went wrong", "error")
        } finally {
          setTaskLoading(false)
        }
      }
    })
  }

  function updateTask(id: Id, activity: string, content: string) {
    const newTasks = tasks.map(task => {
      if (task.id !== id) return task
      return { ...task, activity, note: content }
    })

    setTasks(newTasks)
  }

  function createNewColumn() {
    const columnToAdd: Column = {
      id: generateId(),
      title: `Column ${columns.length + 1}`,
      phone: "",
      startTime: "",
      cutTime: "",
      cap: "",
      vehicleType: "",
      floatAmount: "",
    }

    // setColumns([...columns, columnToAdd])
  }

  function deleteColumn(id: Id) {
    Swal.fire({
      title: "Are you sure, you want to delete this run?",
      text: "You won't be able to revert this!",
      imageUrl: "/icons/100x100-icon.png",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setTaskLoading(true)
          await API.getAction(`Run/Delete?id=${id}`)
          const filteredColumns = columns.filter(col => col.id !== id)
          setColumns(filteredColumns)

          const newTasks = tasks.filter(t => t.id !== id)
          setTasks(newTasks)
          Swal.fire("Deleted!", "Your column has been deleted.", "success")
        } catch (e) {
          console.log(e)
          Swal.fire("Error!", "Something went wrong", "error")
        } finally {
          setTaskLoading(false)
        }
      }
    })
  }

  function updateColumn(payload: Runs) {
    const newColumns = columns.map(col => {
      if (col.id !== payload.id) return col
      return { ...col, ...payload }
    })

    setColumns(newColumns)
  }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column)
      return
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task)
      return
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveColumn(null)
    setActiveTask(null)

    const { active, over } = event

    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveAColumn = active.data.current?.type === "Column"
    if (!isActiveAColumn) return

    setColumns(columns => {
      const activeColumnIndex = columns.findIndex(col => col.id === activeId)
      const overColumnIndex = columns.findIndex(col => col.id === overId)

      const newColumns = arrayMove(columns, activeColumnIndex, overColumnIndex)
      const organizedColumns = newColumns.map((col, index) => ({
        ...col,
        order: index,
      }))

      organizedColumns.forEach(async col => {
        await API.postAction("Run/AddEdit", col)
      })

      return organizedColumns
    })
  }

  async function onDragOver(event: DragOverEvent) {
    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveATask = active.data.current?.type === "Task"
    const isOverATask = over.data.current?.type === "Task"

    if (!isActiveATask) return

    if (isActiveATask && isOverATask) {
      setTasks(tasks => {
        const activeIndex = tasks.findIndex(t => t.id === activeId)
        const overIndex = tasks.findIndex(t => t.id === overId)

        // if (tasks[activeIndex].id !== tasks[overIndex].id) {
        //   tasks[activeIndex].id = tasks[overIndex].id
        //   return arrayMove(tasks, activeIndex, overIndex - 1)
        // }
        const newTasks = arrayMove(tasks, activeIndex, overIndex)

        const organizedTasks = newTasks.map((task, index) => ({
          ...task,
          order: index,
        }))

        organizedTasks.forEach(async task => {
          await API.postAction("RunActivity/AddEdit", task)
        })

        return organizedTasks
      })
    }

    const isOverAColumn = over.data.current?.type === "Column"

    if (isActiveATask && isOverAColumn) {
      let currentTask
      setTasks(tasks => {
        const activeIndex = tasks.findIndex(t => t.id === activeId)
        tasks[activeIndex].runId = overId as string
        currentTask = tasks[activeIndex]
        return arrayMove(tasks, activeIndex, activeIndex)
      })
      await API.postAction("RunActivity/AddEdit", currentTask)
    }
  }

  const toggleShareModal = () => {
    setShowShareModal(!showShareModal)
  }

  const getTask = async (columnId: string) => {
    try {
      const response = await API.getAction(`RunActivity/List?RunId=${columnId}`)
      setTasks((prev: TaskInterface[]) => {
        const newTasks = prev.concat(response.data.response)
        const uniqueData = newTasks
          .filter(
            (item, index, self) =>
              index === self.findIndex(t => t.id === item.id && !item.isState)
          )
          .sort((a, b) => a.order - b.order)
        return uniqueData
      })
    } catch (e) {
      console.log(e)
    }
  }

  return isLoading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        minHeight: showFilter ? "calc(100vh - 350px)" : "calc(100vh - 100px)",
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        padding: "0 40px",
      }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : runs.length === 0 ? (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        minHeight: showFilter ? "calc(100vh - 350px)" : "calc(100vh - 100px)",
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        padding: "0 40px",
      }}
    >
      <i
        className="fas fa-running text-primary"
        style={{ fontSize: "5rem" }}
      ></i>
      <p className="text-center ">No runs added yet</p>
      <button className="btn btn-primary " onClick={toggleAddRunModal}>
        Create Run
      </button>
    </div>
  ) : (
    <>
      <SharingComponent
        show={showShareModal}
        toggle={toggleShareModal}
        item={currentColumn}
        ViaEmail={false}
        ViaLink={false}
        ViaSMS={true}
        ViaClipboard={true}
        type="run"
      />
      <div
        className="m-auto d-flex w-100 align-items-center px-2"
        style={{
          minHeight: showFilter ? "calc(100vh - 350px)" : "calc(100vh - 100px)",
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          padding: "0 40px",
        }}
      >
        <div
          className="m-auto d-flex align-items-center"
          style={{
            height: "100%",
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
          >
            <div
              className="d-flex "
              style={{
                gap: "16px",
              }}
            >
              <div
                className="d-flex"
                style={{
                  gap: "16px",
                }}
              >
                <SortableContext items={columnsId}>
                  {columns.map(col => (
                    <ColumnContainer
                      key={col.id}
                      column={col}
                      taskEditMode={taskEditMode}
                      setTaskEditMode={setTaskEditMode}
                      showFilter={showFilter}
                      setTasks={setTasks}
                      getTask={getTask}
                      setCurrentColumn={setCurrentColumn}
                      toggleShareModal={toggleShareModal}
                      deleteColumn={deleteColumn}
                      updateColumn={updateColumn}
                      createTask={createTask}
                      deleteTask={deleteTask}
                      updateTask={updateTask}
                      tasks={tasks.filter(task => task.runId === col.id)}
                    />
                  ))}
                </SortableContext>
              </div>
            </div>

            {createPortal(
              <DragOverlay>
                {activeColumn && (
                  <ColumnContainer
                    taskEditMode={taskEditMode}
                    setTaskEditMode={setTaskEditMode}
                    getTask={getTask}
                    column={activeColumn}
                    deleteColumn={deleteColumn}
                    updateColumn={updateColumn}
                    setTasks={setTasks}
                    setCurrentColumn={setCurrentColumn}
                    toggleShareModal={toggleShareModal}
                    createTask={createTask}
                    deleteTask={deleteTask}
                    updateTask={updateTask}
                    tasks={tasks.filter(task => task.runId === activeColumn.id)}
                  />
                )}
                {activeTask && (
                  <TaskCard
                    taskEditMode={taskEditMode}
                    setTaskEditMode={setTaskEditMode}
                    task={activeTask}
                    deleteTask={deleteTask}
                    updateTask={updateTask}
                    getTask={() => getTask(activeColumn ? activeColumn.id : "")}
                  />
                )}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </div>
      </div>
    </>
  )
}

export default RunnersBoard
