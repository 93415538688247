import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import EmptyStateImage from "../../assets/img/TODO_emptystage.svg"
import API from "../../common/API"

const FieldSelector = (props: any) => {
  const { t } = useTranslation("global")
  const [records, setRecords] = useState([])
  const [showTip, setShowTip] = useState(false)

  const toggleToolTip = () => {
    setShowTip(!showTip)
  }

  const model = props.model

  if (!model) {
    return
  }

  const requestRecords = async () => {
    let query = ""
    if (model.dependOf && props.obj) {
      query = `${model.dependOf}=${props.obj[model.dependOf]}`
    }
    let requestAPI: any = await API.getAction(
      model.dataSource + (query.length > 0 ? "?" + query : "")
    )

    if (requestAPI.data.status === "ok") {
      setRecords(requestAPI.data.response)
    }
  }

  useEffect(() => {
    console.log(model)
    if (model && model.dataSource && !model.dataSourceLocal) {
      requestRecords()
    } else if (model && model.dataSourceLocal) {
      console.log(model.dataSourceLocal)
      setRecords(model.dataSourceLocal)
    }
  }, [model])

  useEffect(() => {
    if (props.obj) {
      if (model && model.dataSource && !model.dataSourceLocal) {
        requestRecords()
      }
    }
  }, [props.obj])

  const returnField = () => {
    switch (model.htmlType) {
      case "email":
      case "text":
      case "date":
      case "file":
      case "number":
      case "password":
      case "color":
        return (
          <input
            type={model.htmlType}
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              console.log(e.target.value)
              props.updateField(model.name, e.target.value)
            }}
          />
        )

      case "image":
        return props?.data?.[model?.dataSourceValueField] ? (
          <div
            className="d-flex p-2 align-items-center justify-content-center"
            style={{
              height: "170px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#FFF",
            }}
          >
            <img
              src={props?.data?.[model?.dataSourceValueField]}
              alt={model.title}
              className=" w-100 h-100 object-fit-contain"
              style={{ maxWidth: "100%", height: "100%" }}
            />
          </div>
        ) : (
          <div
            className="d-flex p-2 align-items-center justify-content-center"
            style={{
              height: "170px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#FFF",
            }}
          >
            <img
              src={EmptyStateImage}
              className="border-2 w-100 h-100 object-fit-contain"
              style={{ maxWidth: "100%", height: "100%" }}
            />
          </div>
        )
      case "paragraph":
        return props?.data?.[model?.dataSourceValueField].trim().length > 0 ? (
          <p
            className="form-control"
            style={{
              whiteSpace: "pre-wrap",
              fontSize: "0.9rem",
              marginTop: "10px",
              lineHeight: "1.5",
              minHeight: "calc(100dvh - 645px)",
              maxHeight: "calc(100dvh - 645px)",
              overflow: "auto",
            }}
          >
            {props?.data?.[model?.dataSourceValueField]}
          </p>
        ) : (
          <span className="form-control">{t("Not Information Provided")}</span>
        )

      case "fileInput":
        return (
          <>
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <button
                className="btn btn-primary w-100"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  const fileInput = document.querySelector(
                    `input[name=${model.name}]`
                  ) as HTMLInputElement | null
                  if (fileInput) {
                    fileInput.click()
                  }
                }}
              >
                <i
                  className="fa fa-upload"
                  style={{
                    marginRight: "10px",
                  }}
                ></i>
                {t("Select File")}
              </button>
            </div>
            <input
              type="file"
              className="form-control d-none"
              name={model.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files.length > 0) {
                  console.log(e.target.files[0])
                  props.updateField(model.name, e.target.files[0])
                }
              }}
            />
          </>
        )

      case "textarea":
        return (
          <textarea
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              console.log(e.target.value)
              props.updateField(model.name, e.target.value)
            }}
          ></textarea>
        )

      case "select":
        return (
          <select
            className={`select form-control text-black`}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              props.updateField(model.name, e.target.value)
            }}
          >
            <option value="">-</option>
            {records &&
              records.map((item, index) => {
                console.log(item)
                return (
                  <option key={index} value={item[model.dataSourceValueField]}>
                    {item[model.dataSourceTextField]}
                  </option>
                )
              })}
          </select>
        )

      case "bool":
        return (
          <button
            className={
              "btn " + (props.value ? "btn-primary" : "btn-outline-primary")
            }
            onClick={e => {
              props.updateField(model.name, !props.value)
            }}
          >
            {props.value ? (
              <i className="fa-solid fa-toggle-on "></i>
            ) : (
              <i className="fa-solid fa-toggle-off"></i>
            )}
          </button>
        )
      case "label":
        return <label>{props.value}</label>
      case "separator":
        return (
          <>
            <h4>{model.title}</h4>
            <hr />
          </>
        )
      case "container":
        return (
          <>
            <h4>{model.title}</h4>
            <hr />
          </>
        )
      case "time":
        return (
          <input
            type="time"
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              props.updateField(model.name, e.target.value)
            }}
          />
        )
      case "datetime":
        return (
          <input
            type="datetime-local"
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              props.updateField(model.name, e.target.value)
            }}
          />
        )

      default:
        return <></>
    }
  }

  // <select
  //   className={`select w-full`}
  //   name={model.name}
  //   value={props.value ?? ""}
  //   onChange={(e) => {
  //     props.updateField(model.name, e.target.value);
  //   }}
  // >
  //   <option value="">-</option>
  //   {records &&
  //     records.map((item, index) => {
  //       console.log(item);

  //       return (
  //         <option key={index} value={item[model.dataSourceValueField]}>
  //           {item[model.dataSourceTextField]}
  //         </option>
  //       );
  //     })}
  // </select>;
  //debugger;

  if (!model) {
    return <></>
  }
  return (
    <div className={model.containerClass + " my-2"}>
      {model.htmlType !== "separator" &&
        (model?.title === "taskImage" ? null : (
          <>
            <label className="d-flex gap-1 align-items-center">
              {t(model.title)}
              {model.informationContent && (
                <div className="information-content position-relative">
                  <i
                    className="fa-solid fa-circle-info "
                    onMouseEnter={toggleToolTip}
                    onMouseLeave={toggleToolTip}
                  ></i>
                  <span
                    style={{ width: "220px", bottom: "15px", left: "10px" }}
                    className={`position-absolute information-content-text tooltip chartist-tooltip ${
                      showTip ? "tooltip-show" : ""
                    }`}
                  >
                    {model.informationContent}
                  </span>
                </div>
              )}
              {model.required && <i className="text-danger">*</i>}
              <br />
            </label>
          </>
        ))}
      {returnField()}
      {props.errors && props.errors[model.name] ? (
        <div className="invalid text-sm text-danger">
          {props.errors[model.name]}
        </div>
      ) : null}
    </div>
  )
}

export default FieldSelector
