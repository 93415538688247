import { faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useParams } from "react-router"
import { useHistory, useLocation } from "react-router-dom"
import * as uuid from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"
import { VenueFullAddress } from "../../helpers/venueFullAddress"
import { EventType } from "../../interfaces/event.interface"
import { DropDownComponent } from "../Common/DropDownComponent"
import { ToastNotificationSuccess } from "../Common/Notifications/ToastNotification"
import SharingComponent from "../Common/SharingComponent"
import { Event } from "../SideBarEvents/EventItem"
import { RunnerView } from "./RunnerView"

interface RunnerViewState {
  SectionType: string | null
  sectionGUID: string | null
  SectionDeleted: string | null
  SharedByAccountGUID: string | null
  event: EventType
  isLoading: boolean
  data: [{ [key: string]: string | null }]
  accessToken: string | null
}

interface RunnerViewParams {
  eventId: string
  runId: string
}
const initialValue: RunnerViewState = {
  SectionType: "",
  sectionGUID: "",
  SectionDeleted: null,
  SharedByAccountGUID: "",
  event: {} as EventType,
  isLoading: false,
  data: [{}],
  accessToken: "",
}

export const RunnerViewComponent = props => {
  const location = useLocation()
  const params: RunnerViewParams = useParams()
  const currentWitdh = window.innerWidth

  const navigation = useHistory()
  let collabId = ""

  const [currentEvent, setCurrentEvent] = useState()
  const [state, setState] = useState(initialValue)
  const [comments, setComments] = useState([])
  const [openComments, setOpenComments] = useState(false)
  const [commentValue, setCommentValue] = useState("")
  const [loginInfo, setLoginInfo] = useState({
    accessToken: "",
    guest: localStorage.getItem("guestLogin"),
  })

  const [loggedIn, setLoggedIn] = useState(true)
  const [currentUser, setCurrentUser] = useState("")

  useEffect(() => {
    if (!loginInfo?.guest && !loginInfo?.accessToken) {
      setLoggedIn(false)
    }
  }, [loginInfo])

  const getEvent = async (eventGUID: string) => {
    try {
      const res = await fetch(
        `https://service.plnify.com/api/Events/Details?GUID=${eventGUID}`
      )
      const resJson = await res.json()
      const event = resJson.response
      setState(prev => ({ ...prev, event }))
    } catch (e) {
      console.log(e)
    }
  }

  const getCollabData = async () => {
    setState(prev => ({ ...prev, isLoading: true }))
    try {
      const runnerData = await getRunnerData()
      const runs = runnerData.RunActivities.filter(run => !run.Deleted)
      setState(prev => ({
        ...prev,
        SectionType: "runner",
        SectionGUID: params.runId,
        SectionDeleted: null,
        SharedByAccountGUID: null,
        data: runs || [],
        isLoading: false,
      }))
    } catch (error) {
      console.log({ error })
    } finally {
      setState(prev => ({ ...prev, isLoading: false }))
    }
  }

  const getRunnerData = async () => {
    const request = await fetch(
      `https://service.plnify.com/api/Collab/Details?id=${params.runId}`
    )
    const response = await request.json()
    const data = response.response.Data[0]
    await getEvent(data.EventGUID)
    return data
  }

  const getCurrentUser = () => {
    const logInUser = parsedUser()
    const guestUser = JSON.parse(localStorage.getItem("guestLogin") || "{}")
    // setCurrentUser({
    //   firstName: logInUser?.Name || guestUser?.firstName,
    //   lastName: logInUser?.LastName || guestUser?.lastName,
    //   email: logInUser?.Email || guestUser?.Email,
    //   GUID: logInUser?.GUID,
    //   guestGUID: guestUser?.GUID,
    //   type: logInUser?.GUID ? "user" : "guest",
    // })
  }

  const getComments = async () => {
    // const shareRequest = await shareApi.get(
    //   `collab/ListComments?CollabGUID=${collabId}`
    // )
    // const comments = (await shareRequest?.data?.Response) || []
    // setComments(comments.sort((a, b) => new Date(b.Date) - new Date(a.Date)))
  }

  useEffect(() => {
    getCollabData()
    getCurrentUser()
    // getComments()
  }, [loginInfo])

  const Note = "state?.data?.Note"

  const [noteState, setNoteState] = useState({ show: false, Note })
  const [isOver, setIsOver] = useState(false)
  const [showShare, setShowShare] = useState(false)

  const handleAddDescription = () => {
    if (!loggedIn) return
    setNoteState(prev => ({
      ...prev,
      show: true,
    }))
  }

  const handleOpen = () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`)
      return
    }
    setOpenComments(prev => !prev)
  }

  const colors = ["#FAD7A0", "#D2B4DE", "#AED6F1", "#F8C471", "#E74C3C"]
  const randomColor = colors[Math.floor(Math.random() * colors?.length)]

  const handleAddComment = async () => {
    // if (!loggedIn) {
    //   ToastNotificationSuccess(2, `You must be logged in to make changes`)
    //   return
    // }
    // if (!commentValue) return
    // const { firstName, lastName, GUID, guestGUID } = currentUser
    // const thePost = {
    //   GUID: uuid.v4(),
    //   CollabGUID: location.pathname.replace("/c/", ""),
    //   Date: new Date(),
    //   Deleted: null,
    //   Comment: commentValue,
    //   AccountGUID: GUID,
    //   GuestGUID: guestGUID,
    // }
    // try {
    //   const addedComment = await shareApi.post("Collab/AddEditComment", thePost)
    //   setComments(prev => {
    //     const currentState = [...prev]
    //     const dateTime = new Date()
    //     currentState.unshift({
    //       FirstName: firstName,
    //       LastName: lastName,
    //       Comment: commentValue,
    //       Date: dateTime,
    //     })
    //     ToastNotificationSuccess(1, "Comment added succesfully")
    //     setCommentValue("")
    //     return currentState
    //   })
    // } catch (error) {
    //   console.log({ error })
    // }
  }

  const subTitleComplete = () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`)
      return
    }
    let type = state?.event?.EventType
    let subTitle = `${moment(state?.event?.Date)?.format("ddd")} | ${
      type ? type + " | " : ""
    }`

    if (state?.event?.Venue && state?.event?.Venue?.Name) {
      subTitle += VenueFullAddress(state?.event?.Venue)
    } else {
      subTitle +=
        `${state?.event?.City ? state?.event?.City + ", " : ""} ${
          state?.event?.State ? state?.event?.State + ", " : ""
        } ${state?.event?.Country ? state?.event?.Country : ""}` +
        " " +
        moment(state?.event?.Date).format("MMM DD, YYYY")
      // (props.weather ? " | " + props.weather.TimeZoneCode : null)
    }
    return subTitle
  }

  const copyToClipboard = () => {
    if (!loggedIn) {
      ToastNotificationSuccess(2, `You must be logged in to make changes`)
      return
    }
    navigator.clipboard.writeText(
      `${window.location.protocol}//${
        window.location.host
      }/c/${location.pathname.replace("/c/", "")}`
    )
    ToastNotificationSuccess(1, `Link copy to clipboard`)
    handleShareModal()
  }

  const handleClose = () => {
    // const organization = getOrganization()
    navigation.push("/")
  }

  const handleLogOut = () => {
    localStorage.removeItem("guestLogin")
    navigation.push("/")
  }

  const handleShareModal = () => {
    setShowShare(prev => !prev)
  }

  const getCurrentView = () => {
    if (state?.SectionDeleted)
      return (
        <div>
          {
            "Currently you don't have accessed to this section, please contact your administrator."
          }
        </div>
      )

    switch (state.SectionType) {
      case "runner":
        return (
          <RunnerView
            loggedIn={loggedIn}
            TodoActions={{}}
            event={state?.event}
            currentWitdh={currentWitdh}
            state={state}
            setParentState={setState}
            refreshData={getCollabData}
          />
        )
      //   case "guestList":
      //     return (
      //       <GuestListView
      //         loggedIn={loggedIn}
      //         currentWitdh={currentWitdh}
      //         state={state}
      //         setParentState={setState}
      //         event={currentEvent}
      //         CollabGUID={location.pathname.replace("/c/", "")}
      //         user={currentUser}
      //         getCollabData={getCollabData}
      //       />
      //     )
    }
  }

  return state.isLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <Modal show={showShare} onHide={() => handleShareModal()}>
        <SharingComponent
          ViaEmail
          ViaClipboard
          toggle={() => handleShareModal()}
          Type="SHARECOLLAB"
          shareApi={{
            GUID: uuid.v4(),
            SectionType: state?.SectionType,
            SectionGUID: state?.sectionGUID, //example id of the todo
            SharedByAccountGUID: parsedUser()?.ID, // id of the user who shared this
            OrganizationGUID: "", //id of the organization
            ParentSectionGUID: null, //sent it null
            Deleted: null, //sent it null
            Updated: null, //sent it null
            Created: null, //sent it null
            PermissionType: 0, // 0 = readonly ; 1= read and write
            link: location.pathname.replace("/c/", ""),
            user: parsedUser(),
            data: "state.dataToShare",
          }}
          copyToClipBoard={copyToClipboard}
        />
      </Modal>

      {/* <ViewLogin
        isLoggedIn={loggedIn}
        setLoginInfo={setLoginInfo}
        setLoggedIn={setLoggedIn}
      /> */}
      <div className="collab-container">
        <div className="d-flex flex-column border bg-white rounded shadow justify-content-between align-items-center collab-content-container">
          <section
            className="row px-lg-5 w-100 justify-content-center d-flex align-items-center"
            style={{
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div className="overflow-hidden col-8">
              <Event event={state?.event} noIcons={true} noClickable />
            </div>

            <div className="col-4 d-flex justify-content-end align-items-center">
              <DropDownComponent
                className="mr-1 btn btn-link"
                data={[
                  {
                    name: (
                      <span className="p-2">
                        <i className="fa fa-share mr-2"></i>
                        Share
                      </span>
                    ),
                    border: false,
                    trigger: () => handleShareModal(),
                  },
                  {
                    name: (
                      <span className="p-2">
                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                        Sign Out
                      </span>
                    ),
                    border: false,
                    trigger: () => handleClose(),
                  },
                ]}
              />

              <button
                type="button"
                className="btn btn-sm btn-link"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faTimes} color="black" />
              </button>
            </div>
          </section>

          <section className="overflow-hidden w-100 h-100 align-items-start justify-content-center d-flex">
            {getCurrentView()}
          </section>
        </div>
      </div>
    </>
  )
}
