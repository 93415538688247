export default [
  {
    name: "firstName",
    htmlType: "text",
    dbType: "string",
    title: "First Name",
    containerClass: "col-12 col-md-3",
    required: false,
  },
  {
    name: "lastName",
    htmlType: "text",
    dbType: "string",
    title: "Last Name",
    containerClass: "col-12 col-md-3",
    required: false,
  },
  {
    name: "contactNumber",
    htmlType: "text",
    dbType: "string",
    title: "Phone Number",
    containerClass: "col-12 col-md-3",
    required: false,
  },
  {
    name: "email",
    htmlType: "text",
    dbType: "string",
    title: "Email",
    containerClass: "col-12 col-md-3",
    required: false,
  },
]
