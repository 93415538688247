import { useState } from "react"
import CalendarListImage from "../../assets/img/calendar-list.png"
import { Link } from "react-router-dom"
import moment from "moment"
import { useHistory } from "react-router"
import { VenueFullAddress } from "../../helpers/venueFullAddress"
import {
  AccountBox,
  Airplane,
  Attachment,
  Bus,
  ClipboardList,
  Domain,
} from "mdi-material-ui"

export const Event = props => {
  const [meetingModal, setMeetingModal] = useState(false)

  let {
    event,
    noIcons,
    iconsToRemove = [],
    toggle,
    fromSidebar,
    fromGuestList,
    setCurrentEvent,
    renderBy,
    hasDragAndDrop,
  } = props

  const history = useHistory()

  const navigate = guid => {
    history.push(`/calendar/${guid}`)
    toggle()
  }

  const isMeeting = event?.EventType === "Meeting"

  const subTitleComplete = () => {
    let subTitle =
      (event?.Date ? moment(event.Date).format("ddd") : "-") +
      " | " +
      (event?.EventType ? event.EventType : "-") +
      " | "

    if (event?.Venue && event.Venue.Name) {
      subTitle += VenueFullAddress(event.Venue)
    } else {
      subTitle +=
        `${event?.City ? event.City + ", " : ""}` +
        `${event?.State ? event.State + ", " : ""}` +
        `${event?.Country ? event.Country : ""}` +
        " " +
        (event?.Date ? moment(event.Date).format("MMM DD, YYYY") : "-") +
        (props.weather
          ? " | " +
            (props.weather.TimeZoneCode ? props.weather.TimeZoneCode : "-")
          : "")
    }

    return subTitle
  }

  return (
    <div
      className={
        " EventInstance" +
        (props.clickable ? " clickable" : "") +
        (props.selectOnly && props.isSelected ? " active" : "")
      }
      style={{
        width: "100%",
        padding: "0 0",
        overflow: "hidden",
        border: "none",
        minHeight: "85px",
        position: "relative",
        borderRadius: "5px",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
      }}
      onClick={
        renderBy === "guestList"
          ? () => setCurrentEvent(event?.GUID)
          : () => {
              console.log("event")
            }
      }
    >
      {/* <Modal show={meetingModal} onHide={() => setMeetingModal(!meetingModal)}>
        <AddMeetingModal
          onlyToRead
          event={event}
          toggle={() => setMeetingModal(!meetingModal)}
        />
      </Modal> */}

      <div
        onClick={
          isMeeting
            ? () => setMeetingModal(!meetingModal)
            : props.selectOnly
            ? () => setCurrentEvent(event)
            : () => {
                console.log("event")
              }
        }
        className={noIcons ? "col-12 p-0" : "col-12 p-0"}
      >
        <div className="row px-3 col-12">
          <div className="icon d-inline-block position-relative col-2 p-0 m-0 px-1">
            <div className="position-relative">
              <img
                src={CalendarListImage}
                alt="calendar"
                style={{
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  minWidth: "30px",
                  width: "30px",
                  position: "absolute",
                }}
              />
              <span
                title={event?.EventGroup && event?.EventGroup?.Name}
                style={{
                  backgroundColor:
                    event?.EventGroup && event?.EventGroup?.Color,
                  position: "absolute",
                  bottom: 0,
                  left: 1,
                  zIndex: 3,
                  minWidth: "30px",
                  width: "30px",
                }}
              ></span>
            </div>
            <div
              className="calendar-inside"
              style={{ zIndex: 11, left: "10px" }}
            >
              <h3
                style={{
                  color: event?.Date === null ? "transparent" : "black",
                  fontSize: "10px",
                  margin: 0,
                }}
              >
                {event?.Date === null ? "-" : moment(event?.Date).format("MMM")}
              </h3>
              <p
                style={{
                  color: event?.Date === null ? "transparent" : "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                {event?.Date === null ? "-" : moment(event?.Date).format("DD")}
              </p>
            </div>
          </div>
          <div className="px-0 col-10">
            <div className="ml-2 mt-2 px-0 text-truncate">
              <h6
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "1",
                }}
              >
                {event?.Name}
              </h6>

              <p className="text-truncate" title={subTitleComplete()}>
                {subTitleComplete()}
              </p>
            </div>
            {!noIcons ? (
              <div className="d-flex align-items-baseline col-4 mb-2 icons-container text-right px-2">
                {!iconsToRemove.includes("published") &&
                event?.LastSync !== undefined &&
                event?.LastSync !== null ? (
                  <div className=" d-inline badge badge-success mr-2">
                    <span>Published</span>
                  </div>
                ) : (
                  !iconsToRemove.includes("private") && (
                    <div className="d-inline badge badge-secondary mr-2">
                      <span>Private</span>
                    </div>
                  )
                )}
                {!iconsToRemove.includes("flights") &&
                  !noIcons &&
                  event?.FlightsCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <Airplane />
                    </div>
                  )}
                {!noIcons && event?.RunsCount > 0 && props.fromGuestList && (
                  <div className="icon-section d-inline d-flex gap-1">
                    <i className="fa fa-running"></i>
                    <small>{event.RunsCount}</small>
                  </div>
                )}
                {!noIcons && event?.GroundCount > 0 && !props.fromGuestList && (
                  <div className="icon-section d-inline">
                    <Bus />
                  </div>
                )}
                {!noIcons && event?.HotelsCount > 0 && !props.fromGuestList && (
                  <div className="icon-section d-inline">
                    <Domain />
                  </div>
                )}
                {!noIcons &&
                  event?.ScheduleMasterCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <ClipboardList />
                    </div>
                  )}
                {!iconsToRemove.includes("account") &&
                  !noIcons &&
                  event?.ContactsCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <AccountBox />
                    </div>
                  )}
                {!iconsToRemove.includes("attachment") &&
                  !noIcons &&
                  event?.AttachmentsCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <Attachment />
                    </div>
                  )}
                {!noIcons &&
                  event?.GuestListCount > 0 &&
                  !props.fromGuestList && (
                    <div className="icon-section d-inline">
                      <i
                        className="fa fa-ticket"
                        style={{ color: "#4d4d4d" }}
                      ></i>
                    </div>
                  )}
                {!noIcons && (
                  <span
                    className={` d-inline-block event-status ml-2 ${event?.Status}`}
                    title={event?.Status}
                  ></span>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
