import Swal from "sweetalert2"
import EmptyStateImage from "../../assets/img/TODO_emptystage.svg"

interface Props {
  todo: any
  toggleTaskModal: (todo) => void
}
const RunnerTaskCard = ({ todo, toggleTaskModal }: Props) => {
  const showTaskInformation = async task => {
    Swal.fire({
      title: task.Activity,
      imageUrl: task.Image ? task.Image : EmptyStateImage,
      imageWidth: 100,
      imageHeight: 100,
      html: `<p class="pb-5 px-3">${task.Note}</p>`,
      showCloseButton: true,
      showConfirmButton: false,
      // showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: "Close",
    })
  }

  return (
    <div
      className="p-4 border shadow col-12 col-md-6 d-flex gap-2 clickable"
      style={{
        height: "220px",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
      key={todo.id}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        showTaskInformation(todo)
      }}
    >
      <div
        style={{
          width: "40%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {todo.Image ? (
          <img src={todo.Image} className="h-100" />
        ) : (
          <img src={EmptyStateImage} className="h-100" />
        )}
      </div>
      <div
        className="d-flex flex-column"
        style={{
          width: "60%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="card-header w-100 bold text-left">
          <h6
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            {todo.Activity}
          </h6>
        </div>
        <div className="card-body overflow-hidden w-100">
          <div className="d-flex justify-content-between">
            <div className="text-left ">
              <p
                className="w-100 h-100 text-left"
                style={{
                  lineHeight: "1.5",
                  fontSize: "0.8rem",
                }}
              >
                {todo.Note}
              </p>
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary align-self-end mt-2 btn-hover"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            toggleTaskModal(todo)
          }}
        >
          {todo.Done ? "Review" : "Mark as Done"}
        </button>
      </div>
    </div>
  )
}

export default RunnerTaskCard
