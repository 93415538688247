import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import Select from "react-select"
import { status } from "../../helpers/status"

const FilterModal = props => {
  let {
    updateFilters,
    filtersOnEventGroups,
    filtersOnEventStatus,
    filtersOnEventFrom,
    filtersOnEventTo,
    updateFiltersStatus,
    updateFiltersGroups,
    updateFiltersFrom,
    updateFiltersTo,
    toggle,
    groups,
  } = props
  groups = groups.map(item => ({
    id: item.GUID,
    label: item.Name,
    value: ["EventGroupGUID", item.GUID],
  }))

  return (
    <div className="p-3" style={{ minWidth: "unset" }}>
      <div className="d-flex align-items-center">
        <div className=" d-flex w-100 justify-content-between">
          <h4 className="title">Filter Events</h4>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => toggle()}
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
      <div className="row px-0">
        <div className="col-md-6 mb-2">
          <label>From</label>
          <input
            className="form-control p-2 rounded-lg"
            name="dateFrom"
            type="date"
            value={moment(filtersOnEventFrom).format("YYYY-MM-DD")}
            onChange={({ target }) => {
              updateFiltersFrom(target.valueAsDate)
            }}
          />

          {/* <DatePicker
            dateFormat="MMM, dd yyyy"
            selected={filtersOnEventFrom}
            onChange={(date) => {
              updateFiltersFrom(date);
            }}
          /> */}
        </div>
        <div className="col-md-6">
          <label>Until</label>

          <input
            name="dateTo"
            type="date"
            className="form-control p-2 rounded-lg"
            value={moment(filtersOnEventTo).format("YYYY-MM-DD")}
            onChange={({ target }) => updateFiltersTo(target.valueAsDate)}
          />
          {/* <DatePicker
            dateFormat="MMM, dd yyyy"
            selected={filtersOnEventTo}
            onChange={(date) => {
              updateFiltersTo(date);
            }}
          /> */}
        </div>
        {props?.renderBy !== "guestList" && (
          <div className="col-md-6 mb-2">
            <label>Select by Status</label>
            <Select
              value={filtersOnEventStatus}
              placeholder={"Select Status"}
              isMulti
              options={status}
              classNamePrefix="customStatus"
              onChange={(e, trigger) => {
                let filters = e === null ? null : e.map(item => item)
                updateFiltersStatus(filters)
                props.renderBy !== "guestList" && updateFilters(filters)
              }}
            />
          </div>
        )}
        <div className="col-md-6">
          <label>Select by Group</label>

          <Select
            value={filtersOnEventGroups}
            placeholder={"Select Group"}
            isMulti
            options={groups}
            classNamePrefix="groups"
            onChange={(e, trigger) => {
              let filters = e === null ? null : e.map(item => item)
              updateFiltersGroups(filters)
              props.renderBy !== "guestList" && updateFilters(filters)
            }}
          />
        </div>
        <div
          className="text-right d-flex justify-content-end "
          style={{ width: "100%", gap: "5px" }}
        >
          <button
            type="button"
            className="btn-secondary btn text-white"
            onClick={() => {
              let filters = null
              updateFiltersStatus(filters)
              updateFiltersGroups(filters)
              updateFiltersFrom(new Date(2020, 1, 1))
              updateFiltersTo(new Date(2050, 11, 31))
              updateFilters(filters, true)
            }}
          >
            Reset Filter
          </button>
          <button
            type="button"
            className="ml-2 btn-primary btn"
            onClick={() => {
              toggle()
              props?.renderBy === "guestList" && updateFilters([], false)
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
