import React from "react"
import { Routes } from "../../../routes"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Organization } from "../../../interfaces/apiResponse.interface"

const OrganizationUI = (user: any, organizations: any) => {
  const history = useHistory()
  const { t } = useTranslation("global")
  const currentOrganization = JSON.parse(
    localStorage.getItem("CURRENT_ORGANIZATION") || "{}"
  )

  return (
    <div className="text-light align-items-end">
      <hr />
      <div
        className=" text-truncate rounded clickable "
        onClick={() => {
          history.push(Routes.SelectOrganization.path)
        }}
      >
        <div className="d-flex align-items-center text-truncate p-2 px-1 justify-content-center">
          <i className="col-2 fa fa-building text-center"></i>
          <span className="col-10 text-truncate">
            {currentOrganization?.Organization?.Name}
          </span>
        </div>
        {/* <small className="text-muted">{t("change_organization")}</small> */}
      </div>
    </div>
  )
}

export default OrganizationUI
