import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Empty from "../Empty"

const Runners = () => {
  const [t] = useTranslation("global")

  return (
    <>
      {/* {section === "MostSoldProduct" && (
        <MostSoldProduct toggle={toggle} show={true} />
      )} */}

      <Container>
        <Row className="my-4">
          <Col xs={12}>
            <h5 className="display-4">
              <i className="fa fa-running"></i>&nbsp;
              {t("runners")}
            </h5>
          </Col>
        </Row>

        <div className="h-100 col-12 justify-content-center d-flex flex-column align-items-center">
          <i className="fa fa-running fa-5x text-muted"></i>
          <div className="d-flex flex-column justify-content-between">
            <span className="text-muted">{t("No Runners Added Yet")}</span>
            <button className="btn btn-primary">{t("Add Runner")}</button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Runners
