import React from "react"
import { Col, Row } from "react-bootstrap"

const ActionBar = props => {
  return (
    <div className="card shadow-sm mb-2">
      <div className="card-body py-2 px-4">
        <Row>
          <Col style={{ fontWeight: "bold" }}>
            {props.icon ? (
              <span className="me-2">
                <i className={props.icon + " fa-2x"}></i>
              </span>
            ) : null}
            <h4 className="d-inline">{props.title}</h4>
          </Col>
          <Col xs={"auto"}>{props.actions}</Col>
        </Row>
      </div>
    </div>
  )
}

export default ActionBar
