import CRUDField from "../core/genericCRUD/CRUDField"

const RunnersDTO: CRUDField[] = [
  {
    name: "runnerInformationSection",
    title: "Runner Information",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,

    containerClass: "col-md-6 col-12",
  },
  {
    name: "firstName",
    title: "First Name",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "lastName",
    title: "Last Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "vehicleType",
    title: "Vehicle Type",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSourceLocal: [
      {
        id: "SUV",
        name: "SUV",
      },

      {
        id: "Sedan",
        name: "Sedan",
      },
      {
        id: "Van",
        name: "Van",
      },
      {
        id: "Sprinter Van",
        name: "Sprinter Van",
      },
      {
        id: "Shuttle",
        name: "Shuttle",
      },
      {
        id: "Bus",
        name: "Bus",
      },
      {
        id: "Truck",
        name: "Truck",
      },
      {
        id: "Mini Van",
        name: "Mini Van",
      },
      {
        id: "Cargo Van",
        name: "Cargo Van",
      },
      {
        id: "Others",
        name: "Others",
      },
    ],
  },
  {
    name: "contactNumber",
    title: "Contact Number",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "email",
    title: "Email",
    htmlType: "email",
    dbType: "string",
    required: false,
    containerClass: "col-md-12 col-12",
  },
  // {
  //   name: "secondLastName",
  //   title: "Second Last Name",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  // },

  {
    name: "generalSection",
    title: "General",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },
  {
    name: "startTime",
    title: "Start Time",
    htmlType: "time",
    dbType: "time",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "cutTime",
    title: "Cut Time",
    htmlType: "time",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "cap",
    title: "Cap",
    htmlType: "number",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    informationContent: "The maximum number of hours allowed a runner can work",
  },

  {
    name: "floatAmount",
    title: "Float Amount",
    htmlType: "number",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
  },

  // {
  //   name: "birthPlaceId",
  //   title: "Birth Place",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "civilStatudId",
  //   title: "Civil Status",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "socialGroupId",
  //   title: "Social Group",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "occupationId",
  //   title: "Occupation",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "nationalityId",
  //   title: "Nationality",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "separatorApodos",
  //   title: "Apodos Reconocidos",
  //   htmlType: "separator",
  //   dbType: "string",
  //   required: false,

  //   containerClass: "col-12",
  // },
  // {
  //   name: "alias",
  //   title: "Alias",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  // },
  // {
  //   name: "alias2",
  //   title: "Alias 2",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  // },
  // // caracteristicas fisicas
  // {
  //   name: "separator",
  //   title: "Características Fisicas",
  //   htmlType: "separator",
  //   dbType: "string",
  //   required: false,

  //   containerClass: "col-12",
  // },
  // {
  //   name: "weightId",
  //   title: "Weight",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "heightId",
  //   title: "Height",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "skinColorId",
  //   title: "Skin Color",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "physicalBuildId",
  //   title: "Physical Build",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // //   Facial Characteristics
  // {
  //   name: "separator",
  //   title: "Características del rostro",
  //   htmlType: "separator",
  //   dbType: "string",
  //   required: false,

  //   containerClass: "col-12",
  // },
  // {
  //   name: "hairTypeId",
  //   title: "Hair",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "eyeTypeId",
  //   title: "Eyes",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "noseTypeId",
  //   title: "Nose",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "mouthTypeId",
  //   title: "Mouth",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "earTypeId",
  //   title: "Ears",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "expresionLineId",
  //   title: "Expresion Lines",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "faceId",
  //   title: "Face",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },

  // //   Rasgos Particulares
  // {
  //   name: "separator",
  //   title: "Particular characteritiscs",
  //   htmlType: "separator",
  //   dbType: "string",
  //   required: false,

  //   containerClass: "col-12",
  // },
  // {
  //   name: "tattooPlaceId",
  //   title: "Tattoo Place",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "tattooDescription",
  //   title: "Tattoo description",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  // },
  // {
  //   name: "tattooLettersAndNumber",
  //   title: "Tattoo Letters and Number",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  // },
  // {
  //   name: "scarPlaceId",
  //   title: "Scar Place",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "stainsId",
  //   title: "Stains",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "burnPlaceId",
  //   title: "Burn Place",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "voiceQualityId",
  //   title: "Voice Quality",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "discapacityId",
  //   title: "Discapacity",
  //   htmlType: "select",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-3 col-12",
  //   dataSourceTextField: "name",
  //   dataSourceValueField: "id",
  // },
  // {
  //   name: "otherParticularity",
  //   title: "Other Particularities",
  //   htmlType: "textarea",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-12",
  // },
]

export const vehicleTypes = [
  {
    id: "SUV",
    name: "SUV",
  },

  {
    id: "Sedan",
    name: "Sedan",
  },
  {
    id: "Van",
    name: "Van",
  },
  {
    id: "Sprinter Van",
    name: "Sprinter Van",
  },
  {
    id: "Shuttle",
    name: "Shuttle",
  },
  {
    id: "Bus",
    name: "Bus",
  },
  {
    id: "Truck",
    name: "Truck",
  },
  {
    id: "Mini Van",
    name: "Mini Van",
  },
  {
    id: "Cargo Van",
    name: "Cargo Van",
  },
  {
    id: "Others",
    name: "Others",
  },
]

export default RunnersDTO
