import CRUDField from "../core/genericCRUD/CRUDField"

const CompleteTaskFileds: CRUDField[] = [
//   {
//     name: "runnerInformationSection",
//     title: "Task Information",
//     htmlType: "separator",
//     dbType: "string",
//     required: false,
//     containerClass: "col-12 mb-0 pb-0",
//   },
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "image",
    title: "taskImage",
    htmlType: "image",
    dbType: "string",
    required: false,
    containerClass: "col-12 mb-2",
  },
  {
    name: "description",
    title: "Description",
    htmlType: "paragraph",
    dbType: "string",
    dataSourceValueField: "Note",
    required: false,
    containerClass: "col-12",
  },
//   {
//     name: "startTime",
//     title: "Start Time",
//     htmlType: "time",
//     dbType: "time",
//     required: true,
//     containerClass: "col-6 ",
//   },
//   {
//     name: "endTime",
//     title: "End Time",
//     htmlType: "time",
//     dbType: "string",
//     required: true,
//     containerClass: "col-6 ",
//   },
  {
    name: "amountSpent",
    title: "Amount Spent",
    htmlType: "number",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },
  {
    name: "runnerNote",
    title: "Note",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },

  {
    name: "file",
    title: "File Upload",
    htmlType: "fileInput",
    dbType: "string",
    required: false,
    containerClass: "col-12",
  },
]

export default CompleteTaskFileds
