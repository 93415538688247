export const status = [
  {
    id: 1,
    label: "Confirmed",
    value: ["Status", "Confirmed"],
  },
  {
    id: 2,
    label: "Cancelled",
    value: ["Status", "Cancelled"],
  },
  {
    id: 3,
    label: "Tentative/Hold",
    value: ["Status", "Tentative/Hold"],
  },
  {
    id: 4,
    label: "Postponed",
    value: ["Status", "Postponed"],
  },
]
