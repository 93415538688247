export const VenueFullAddress = (venue) => {
    let fullAddress = "";
    if (venue !== null && venue.Address) {
      fullAddress += venue.Address;
    }
    if (venue !== null && venue.City) {
      fullAddress += fullAddress.length > 0 ? ", " + venue.City : venue.City;
    }
    if (venue !== null && venue.ZipCode) {
      fullAddress +=
        fullAddress.length > 0 ? ", " + venue.ZipCode : venue.ZipCode;
    }
    if (venue !== null && venue.State) {
      fullAddress += fullAddress.length > 0 ? ", " + venue.State : venue.State;
    }
    if (venue !== null && venue.Country) {
      fullAddress +=
        fullAddress.length > 0 ? ", " + venue.Country : venue.Country;
    }
    fullAddress = fullAddress.replace(" ,", ",");
  
    return fullAddress;
  };