

import Runners from "../components/Runners/Runners";



const RunnersPage = () => {
    return (<Runners />)
}

export default RunnersPage;