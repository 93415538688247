import { toast } from "react-toastify"

type ToastNotification = (id: number, message: string) => void

export const ToastNotificationError: ToastNotification = (id, message) => {
  toast.error(message, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    toastId: id,
  })
}

export const ToastNotificationSuccess: ToastNotification = (id, message) => {
  toast.success(message, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    toastId: id,
  })
}
