import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const UserComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("users") ?? ""}
      
      getRecordsPath={`users/list?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`users/AddEditUser?refer=${window.location.host}`}
      deleteRecordPath="users/DeleteExtrahour"
      fieldsList={[
        {
          name: "firstName",
          title: t("firstName"),
        },
        {
          name: "lastName",
          title: t("lastName"),
        },
        {
          name: "email",
          title: t("email"),
        },
        {
          name: "organizationRoleName",
          title: t("role"),
        },
      
      
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "firstName",
          htmlType: "text",
          dbType: "string",
          title: t("firstName") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "lastName",
          htmlType: "text",
          dbType: "string",
          title: t("lastName") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
        {
          name: "email",
          htmlType: "email",
          dbType: "string",
          title: t("email") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },

        {
          name: "password",
          htmlType: "password",
          dbType: "string",
          title: t("password") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },

        {
          name: "organizationRoleId",
          htmlType: "select",
          dbType: "string",
          title: t("role") ?? "",
          containerClass: "col-12 col-md-6",
          dataSource: "organization/roles?OrganizationId=" + user.OrganizationId,
          dataSourceTextField: "name",
          dataSourceValueField: "id",
          required: true,
        },
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
        {
          name: "CurrentUserId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.ID,
        },
      ]}
    />
  )
}

export default UserComponent
