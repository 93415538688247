import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { EventType } from "../../interfaces/event.interface"
import { Runs } from "../../interfaces/runs.interface"
import ActionBar from "../core/ActionBar"
import Filter from "../core/Filter/Filter"
import AddRunnerModal from "../Dashboard/AddRunnerModal"
import RunnersBoard from "../Dashboard/RunnersBoard"
import SidebarEvents from "../SideBarEvents/SidebarEvents"
import HomeFilterFields from "./HomeFilter.fields"
import RunnersListView from "../Dashboard/RunnersListView"
import { FilterObject } from "../../interfaces/filterObj.interface"
import Swal from "sweetalert2"

const HomeComponent = props => {
  // PROPERTIES
  const [showAddEdit, setShowAddEdit] = useState(false)

  const [selectedEvent, setSelectedEvent] = useState({})
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => setShowFilter(!showFilter)
  const [filterObj, setFilterObj] = useState<FilterObject>({} as FilterObject)
  const { t } = useTranslation("global")
  const filterFields = HomeFilterFields
  const [hideEvents, setHideEvents] = useState(false)
  const [expandEventsSidebar, setExpandEventsSidebar] = useState(true)
  const [events, setEvents] = useState<EventType[]>([])
  const [runs, setRuns] = useState<Runs[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [boardView, setBoardView] = useState("board")

  const organization = JSON.parse(
    localStorage.getItem("CURRENT_ORGANIZATION") ?? ""
  )
  const currentOrganization = organization.OrganizationGUID

  const getEvents = async () => {
    const user = parsedUser()

    const currentAccount = user.ID
    const res = await fetch(
      `https://service.plnify.com/api/Events/listClean?OrganizationGUID=${currentOrganization}&AccountGUID=${currentAccount}`
    )
    const resJson = await res.json()
    const events = resJson.response
    setEvents(events)
  }

  useEffect(() => {
    getEvents()
  }, [])

  //   METHODS
  const getFilterValues = obj => {
    setFilterObj(obj)
  }
  const getRecords = async () => {
    setIsLoading(true)
    try {
      const response = await API.getAction(
        `Run/List?EventGUID=${selectedEvent}&OrganizationID=${currentOrganization}`
      )
      setRuns(response.data.response.sort((a, b) => a.order - b.order))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectEvent = event => {
    setSelectedEvent(event)
  }

  const toggleSidebarEvents = () => {
    setHideEvents(prev => !prev)
  }

  const cleanedEvents = events
    .filter(event => event.EventType !== "Day Off")
    .filter(event => event.EventType !== "Travel Day")
    .filter(event => event.EventType !== "Travel")

  useEffect(() => {
    getRecords()
  }, [selectedEvent])

  const toggleBoardView = () => {
    setBoardView(prev => (prev === "list" ? "board" : "list"))
  }

  const toggleSideBarFilters = () => {
    setExpandEventsSidebar(prev => !prev)
  }

  const toggleAddEdit = () => {
    if (Object.keys(selectedEvent).length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select an event and try again",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }
    setShowAddEdit(!showAddEdit)
  }
  return (
    <Container
      className="m-0 p-0 w-100"
      style={{
        maxWidth: "100%",
        height: "calc(100vh - 60px)",
        justifyContent: "center",
        display: "flex",
      }}
    >
      {showAddEdit && (
        <AddRunnerModal
          toggle={toggleAddEdit}
          title={t("Runners")}
          cssClass="modalRight"
          selectedEvent={selectedEvent}
          event={events.find(event => event.GUID === selectedEvent)}
          runsQuantity={runs.length}
          getEvents={getRecords}
        />
      )}

      <div className="row w-100">
        <div
          className={`${
            expandEventsSidebar ? "col-md-3 col-12" : "d-none"
          }  h-100 transition-all`}
        >
          <SidebarEvents
            eventConatinerClass="w-100 d-flex flex-column h-100"
            eventSecondContainerClass="h-100"
            eventThirdContainerClass="card shadow-sm h-100"
            eventHeadContainerClass="d-flex justify-content-between align-items-center p-2 border-bottom"
            eventContent="bg-white"
            scrollbarsStyles={{ height: "calc(100vh - 120px)" }}
            toggle={toggleSidebarEvents}
            events={cleanedEvents}
            selectedEvent={selectedEvent}
            setCurrentEvent={setSelectedEvent}
            hideSearch
            toggleSideEvents={toggleSideBarFilters}
            fromGuestList
            isMobile={false}
            renderBy={"guestList"}
            noClickable
          />
        </div>
        <div
          className={`${
            expandEventsSidebar ? "col-md-9" : "col-md-12"
          } col-12 position-relative transition-all`}
        >
          {!expandEventsSidebar && (
            <button
              title="Show Events"
              className="btn btn-primary border-0 position-absolute event-sidebar-btn"
              onClick={toggleSideBarFilters}
              style={{
                zIndex: "50",
                left: "8px",
                top: "4px",
              }}
            >
              <i
                className="fas fa-angle-right"
                style={{ marginLeft: "0px" }}
              ></i>
            </button>
          )}
          <ActionBar
            title={t("Runners Board")}
            icon="fa fa-home"
            actions={
              <>
                <button
                  className="ms-2 btn  btn-sm btn-primary mx-2"
                  onClick={() => toggleBoardView()}
                >
                  {boardView === "list" ? (
                    <i className="fa fa-th-large" />
                  ) : (
                    <i className="fa fa-list" />
                  )}
                </button>
                <button
                  className={
                    "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                  }
                  onClick={() => toggleFilter()}
                >
                  <i
                    className={`fa fa-filter ${showFilter ? "text-white" : ""}`}
                  />
                </button>
                <button
                  className={"ms-2 btn  btn-sm btn-primary  "}
                  onClick={() => toggleAddEdit()}
                >
                  <i className="fa fa-plus" />
                </button>
              </>
            }
          ></ActionBar>

          {showFilter && (
            <div className="my-4">
              <Filter
                toggleFilter={toggleFilter}
                fields={filterFields}
                filterAction={() => {
                  getRecords()
                }}
                setFilterFields={getFilterValues}
              />
            </div>
          )}

          {Object.keys(selectedEvent).length === 0 ? (
            <>
              <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                <i className="fa fa-5x fa-calendar text-primary"></i>
                <h5 className="text-center mt-2">
                  {t("Select an event to view your runners")}
                </h5>
                {!expandEventsSidebar && (
                  <button
                    title="Show Events"
                    className="btn btn-primary border-0"
                    onClick={toggleSideBarFilters}
                  >
                    Show Events
                  </button>
                )}
              </div>
            </>
          ) : boardView === "list" || Object.keys(filterObj).length > 0 ? (
            <RunnersListView runs={runs} filterObj={filterObj} />
          ) : (
            <RunnersBoard
              isLoading={isLoading}
              showFilter={showFilter}
              runs={runs}
              toggleAddRunModal={toggleAddEdit}
            />
          )}
        </div>
      </div>
    </Container>
  )
}

export default HomeComponent
