import React from "react"

import { BrowserRouter as Router } from "react-router-dom"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// core styles

// vendor styles
// import "@fortawesome/fontawesome-free/css/all.css"
// import broken;
// App Styles
import App from "./App"

//  Modules for translation
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import { resources } from "./common/languages"
import { createRoot } from "react-dom/client"
import "./scss/volt.scss"
import "./css/app.css"

const container = document.getElementById("root")

const root = createRoot(container)
i18next.init({
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  lng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en_US",
  resources: resources,
  defaultNS: "en_US",
})

root.render(
  <Router>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Router>
)

// <Route path="/" component={Islogin() ? App : Login} exact/>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

serviceWorkerRegistration.unregister()
