import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import EmptyStateImage from "../../assets/img/TODO_emptystage.svg"
import API from "../../common/API"
import RunnerTaskCard from "./RunnerTaskCard"
import CompleteTaskModal from "./CompleteTaskModal"
import {
  Activities,
  RunnerTask,
  StateTask,
} from "../../interfaces/runnerTask.interface"
import Swal from "sweetalert2"

interface Props {
  loggedIn: boolean
  TodoActions: any
  currentWitdh: number
  state: any
  setParentState: any
  refreshData: () => void
  event: any
}

export const RunnerView = ({
  loggedIn,
  TodoActions,
  currentWitdh,
  state,
  setParentState,
  event,
  refreshData,
}: Props) => {
  const location = useLocation()
  const [localTodos, setLocalTodos] = useState(state.data)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [window.innerWidth])

  const toggleTask = async task => {
    // if (!loggedIn) {
    //   ToastNotificationSuccess(2, `You must be logged in to make changes`)
    //   return
    // }
    try {
      const toggledTask = {
        ...task,
        Done: !task.Done ? new Date().toISOString() : null,
      }

      const updatedTask = {
        activity: task.Activity,
        id: task.Id,
        runId: task.RunId,
        note: task.Note,
        done: !task.Done ? new Date().toISOString() : null,
        attachments: null,
        created: null,
        order: task.Order || 0,
      }

      await API.postAction("RunActivity/AddEdit", updatedTask)
      setLocalTodos(prev => {
        const Tasks = prev
        let TaskToUpdate = Tasks.map((todo, index) => {
          if (todo.Id === task.Id) {
            return toggledTask
          }
          return todo
        })

        return TaskToUpdate
      })
    } catch (e) {
      console.log(e)
    }
  }

  const todoTaskClean = localTodos.filter(
    todoTask => !todoTask.Deleted && !todoTask.Done
  )

  useEffect(() => {
    setParentState(prev => {
      return { ...prev, dataToShare: todoTaskClean }
    })
  }, [])

  const todoTaskCleanCompleted = localTodos?.filter(
    todoTask => !todoTask.Deleted && todoTask.Done
  )

  const [showTaskModal, setShowTaskModal] = useState(false)
  const [currentTask, setCurrentTask] = useState<StateTask>({} as StateTask)

  const toggleTaskModal = async task => {
    setCurrentTask(task)
    setShowTaskModal(!showTaskModal)
  }

  return (
    <>
      <CompleteTaskModal
        title={currentTask?.Activity}
        show={showTaskModal}
        task={currentTask}
        event={event}
        toggle={toggleTaskModal}
        toggleTask={toggleTask}
        refreshData={refreshData}
      />
      <div className="mb-3">
        <h5 className="font-weight-bold">{state?.data?.Name}</h5>
        {/* <span>{moment(state?.data?.taskDate).format("MMM DD, YYYY")}</span> */}
      </div>

      {todoTaskClean?.length === 0 && todoTaskCleanCompleted?.length === 0 ? (
        <div
          style={{
            height: "calc(100vh - 200px)",
            overflowY: "auto",
          }}
          className="d-flex flex-column w-100 h-75 align-items-center justify-content-center"
        >
          <img
            src={EmptyStateImage}
            className="mb-5"
            style={{
              width: "50%",
              height: "40%",
            }}
          />
          <h5 className="text-muted">{"No Tasks added yet"}</h5>
        </div>
      ) : (
        todoTaskClean?.length > 0 && (
          <div className="row col-12 align-items-center justify-content-center">
            <div
              className="px-lg-5 px-3 d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #e0e0e0",
                height: "60px",
              }}
            >
              <h4 className="p-0 m-0">Pending Task</h4>
              <div className="d-flex gap-1">
                <button className="btn btn-primary gap-1 d-flex align-items-center">
                  <span className="d-none d-md-block">{"Filters"}</span>
                  <i className="fa fa-filter"></i>
                </button>
                <button className="btn btn-secondary gap-1 d-flex align-items-center">
                  <span className="d-none d-md-block">{"List View"}</span>
                  <i className="fa fa-list"></i>
                </button>
                {/* <button className="btn btn-secondary">
                  <i className="fa fa-search"></i>
                </button> */}
              </div>
            </div>
            <div
              className="row overflow-auto gx-3 gy-3 my-0 pb-2 pb-md-4"
              style={{
                maxHeight: "calc(100vh - 150px)",
              }}
            >
              {todoTaskClean?.map(todo => {
                return (
                  <RunnerTaskCard
                    key={todo.Id}
                    todo={todo}
                    toggleTaskModal={toggleTaskModal}
                  />
                )
              })}
            </div>
          </div>
        )

        // <div
        //   style={{
        //     height: "calc(100dvh - 310px)",
        //     overflowY: "auto",
        //   }}
        // >
        //   <div className="mb-3">
        //     <div //ref={drag}
        //     >
        //       {todoTaskClean?.length > 0 && (
        //         <Table className="border">
        //           <thead>
        //             <tr className="list-subheader">
        //               <th style={{ fontSize: "14px" }}>Complete</th>
        //               <th className="text-center" style={{ fontSize: "14px" }}>
        //                 Time
        //               </th>
        //               <th className="text-center" style={{ fontSize: "14px" }}>
        //                 Title
        //               </th>
        //               <th className="text-center" style={{ fontSize: "14px" }}>
        //                 Description
        //               </th>
        //               {/* <th className="text-center" style={{ fontSize: "14px" }}>
        //                 Priority
        //               </th> */}
        //             </tr>
        //           </thead>
        //           <tbody>
        //             {todoTaskClean?.map(todo => {
        //               return (
        //                 <TaskViewTable
        //                   key={todo.Id}
        //                   todo={todo}
        //                   toggleTask={toggleTask}
        //                   assignee={todo.Assignees}
        //                   mobile={isMobile}
        //                 />
        //               )
        //             })}
        //           </tbody>
        //         </Table>
        //       )}
        //     </div>
        //   </div>

        //   {todoTaskCleanCompleted?.length > 0 ? (
        //     <>
        //       <span className="text-muted">COMPLETED</span>
        //       <TodoAccordion
        //         todos={todoTaskCleanCompleted}
        //         toggleTask={toggleTask}
        //         assignees={state?.data?.TodoAssignees}
        //       />
        //     </>
        //   ) : (
        //     ""
        //   )}
        // </div>
      )}
    </>
  )
}
