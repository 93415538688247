import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { t } from "i18next"
import React, { useState } from "react"
import Swal from "sweetalert2"
import { useOutsideClick } from "../../Hooks/useOutsideClick"
import API from "../../common/API"
import { Id } from "../../interfaces/column.interface"
import { TaskInterface } from "../../interfaces/task.interface"
import PlnifyLogo from "../../assets/img/100x100-icon.png"

interface Props {
  task: TaskInterface
  deleteTask: (id: Id) => void
  updateTask: (id: Id, title: string, content: string) => void
  getTask: () => void
  taskEditMode: { id: Id; isEditMode: boolean }
  setTaskEditMode: React.Dispatch<
    React.SetStateAction<{
      id: Id
      isEditMode: boolean
    }>
  >
}

export function TaskCard({
  task,
  deleteTask,
  updateTask,
  getTask,
  taskEditMode,
  setTaskEditMode,
}: Props) {
  const [mouseIsOver, setMouseIsOver] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const isEditMode = taskEditMode.id === task.id && taskEditMode.isEditMode
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id || "",
    data: {
      type: "Task",
      task,
    },
    disabled: isEditMode,
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  const toggleEditMode = async (
    e: { stopPropagation: () => void } | undefined
  ) => {
    e?.stopPropagation()
    setIsLoading(true)
    setTaskEditMode(prev => {
      return {
        id: task.id || "",
        isEditMode: !prev.isEditMode,
      }
    })
    setMouseIsOver(false)

    const payload: TaskInterface = {
      id: task.id,
      activity: task.activity,
      note: task.note,
      done: task.done || null,
      runId: task.runId,
      attachments: task.attachments,
      created: task.created,
      order: task.order,
    }

    try {
      if (!task.id) throw new Error("Task ID is missing")
      if (!task.activity) throw new Error("Task activity is missing")
      await API.postAction("RunActivity/AddEdit", payload)
      await getTask()
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const ref = useOutsideClick<HTMLDivElement>(toggleEditMode)

  if (isDragging) {
    return <div ref={setNodeRef} style={style} className="task-item-draggin" />
  }

  if (isEditMode) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="task-item-edit"
      >
        <div className="d-flex flex-column w-100 p-2" ref={ref}>
          <input
            type="text"
            autoFocus
            placeholder="Task title here"
            value={task.activity || ""}
            className="border-0 border-b-2 border-blue-500"
            // onClick={e => e.stopPropagation()}
            onChange={e => {
              e.stopPropagation()
              updateTask(task.id || "", e.target.value, task.note || "")
            }}
          />
          <textarea
            style={{
              paddingRight: "8px",
            }}
            className="task-content-textarea"
            value={task.note || ""}
            onClick={e => e.stopPropagation()}
            onBlur={e => e?.stopPropagation()}
            placeholder="Task content here"
            onKeyDown={e => {
              e.stopPropagation()
              if (e.key === "Enter" && e.shiftKey) {
                toggleEditMode(e)
              }
            }}
            onChange={e => {
              e.stopPropagation()
              updateTask(task.id || "", task.activity || "", e.target.value)
            }}
          />
        </div>
      </div>
    )
  }

  console.log({
    task,
  })

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="task-item d-flex align-items-center position-relative"
      onMouseEnter={() => {
        setMouseIsOver(true)
      }}
      onMouseLeave={() => {
        setMouseIsOver(false)
      }}
    >
      <div className="d-flex align-items-center w-100 flex-column h-100">
        <span
          className="w-100 border-0"
          style={{
            fontSize: "16px",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "30px",
          }}
          onClick={toggleEditMode}
        >
          <strong>{task.activity}</strong>
        </span>
        <p
          style={{
            whiteSpace: "pre-wrap",
            overflowY: "auto",
            overflowX: "hidden",
            height: "105px",
            width: "100%",
            cursor: "pointer",
            paddingRight: "8px",
          }}
          className="my-auto border-0"
          onClick={toggleEditMode}
        >
          {task.note}
        </p>

        <div
          className="d-flex justify-content-end w-100"
          style={{
            gap: "8px",
          }}
        >
          {task.attachments?.length > 0 && task.attachments[0].path && (
            <div className="d-flex align-items-center">
              <i className="fa fa-paperclip" />
              <span className="ms-1">{task.attachments?.length}</span>
            </div>
          )}
          {/* <div className="d-flex align-items-center">
            <i className="fa fa-clock" />
            <span className="ms-1">
              {moment(task.created).format("MM/DD/YYYY")}
            </span>
          </div> */}

          {task.runnerNote && (
            <div
              className="d-flex align-items-center"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                Swal.fire({
                  title: "Runner Note",
                  text: task.runnerNote || "",
                  imageUrl: PlnifyLogo,
                  confirmButtonText: "Close",
                })
              }
            >
              <i
                className="fa fa-comment"
                style={{
                  color: "#4ECDC4",
                }}
              />
            </div>
          )}

          {task.done && (
            <div className="d-flex align-items-center text-secondary">
              <i className="fa fa-circle-check " />
              <span className="ms-1">{t("Done")}</span>
            </div>
          )}
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            position: "absolute",
            right: "0",
            bottom: "0",
            background: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {mouseIsOver && (
        <button
          onClick={() => {
            deleteTask(task.id || "")
          }}
          style={{
            right: "5px",
            top: "5px",
          }}
          className="position-absolute p-1 px-2 rounded btn btn-danger"
        >
          <i className="fa fa-trash" />
        </button>
      )}
    </div>
  )
}
