import React, { useContext, useEffect, useState } from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { userAvatar } from "../../../common/helpers"
import { parsedUser } from "../../../common/GetCurrentUser"
import { useTranslation } from "react-i18next"
import { Routes } from "../../../routes"
import ClearUser from "../../../common/ClearUser"
import { db } from "../../../common/db"
import { useHistory } from "react-router-dom"

const OmniBar = props => {
  const user = parsedUser()
  const { t } = useTranslation("global")
  const history = useHistory()

  return (
    <>
      <div className="omniBar d-sm-none d-md-block d-lg-block text-dark">
        <DropdownButton
          align="end"
          className="p-0"
          title={
            <div
              className="bg-secondary  d-flex align-items-center justify-content-center rounded text-uppercase"
              style={{
                width: "40px",
                height: "40px",
              }}
            >
              {user && user.Email.substring(0, 1)}
            </div>
          }
        >
          <Dropdown.Item
            className="fw-bold"
            onClick={() => history.push(Routes.Profile.path)}
          >
            <i className="fa fa-user-circle me-2"></i> {t("profile")}
          </Dropdown.Item>
          {/* <Dropdown.Item
            className="fw-bold"
            onClick={() => history.push(Routes.Profile.path)}
          >
            <i className="fa-solid fa-users me-2"></i> 
            {t("organization")}
          </Dropdown.Item> */}

          <Dropdown.Item
            className="fw-bold"
            as="button"
            onClick={() => {
              window.open("mailto:info@valegos.com", "_blank")
            }}
          >
            <i className="far fa-life-ring me-2"></i> {t("Help")}
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            className="fw-bold"
            onClick={() => {
              ClearUser()
              history.push(Routes.SignIn.path)
            }}
          >
            <i className="fas fa-sign-out-alt me-2"></i>
            {t("logout")}
          </Dropdown.Item>
        </DropdownButton>
        <button
          className={
            (props.settings && props.settings.application === "employees"
              ? "active "
              : "") + "btn btn-sm btn-block"
          }
          style={{ color: "white" }}
          onClick={async () => {
            let settings = props.settings
            settings.application = "employees"
            await db.settings.put(settings)
            history.push(Routes.Home.path)
          }}
        >
          <i className="fas  fa-home"></i>
          <br />
          <small>{t("Regular")}</small>
        </button>
        {user.RoleId === "admin" && (
          <button
            className={
              (props.settings && props.settings.application === "admin"
                ? "active "
                : "") + "btn btn-sm btn-block"
            }
            style={{ color: "yellow" }}
            onClick={async () => {
              let settings = props.settings
              settings.application = "admin"
              await db.settings.put(settings)
              history.push(Routes.AdminUsers.path)
            }}
          >
            <i className="fas  fa-bolt"></i>
            <br />
            <small>{t("Admin")}</small>
          </button>
        )}

        {/* <button
          className={
            (props.settings.application === "operations" ? "active " : "") +
            "btn btn-sm btn-block"
          }
          style={{ color: "orangeRed" }}
          onClick={async () => {
            let settings = props.settings
            settings.application = "operations"
            await db.settings.put(settings)
            history.push(Routes.DashboardPayroll.path)
          }}
        >
          <i className="fas fa-toolbox"></i>
          <br />
          <small>{t("operations_short")}</small>
        </button> */}
      </div>
    </>
  )
}

export default OmniBar
