import React, { useEffect, useState } from "react"

import { Col, Row, Container } from "react-bootstrap"

import API from "../../common/API"
import { Routes } from "../../routes"
import BgImage from "../../assets/img/illustrations/signin.svg"

import { userAvatar } from "../../common/helpers"
import { parsedUser } from "../../common/GetCurrentUser"
import AddEditOrganization from "./AddEditOrganization"
import { useHistory } from "react-router-dom"

//Translation
import { useTranslation } from "react-i18next"

import {
  APIResponse,
  OrganizationI,
} from "../../interfaces/organization.interface"

const SelectOrganization = props => {
  const history = useHistory()

  const [organizations, setOrganizations] = useState<OrganizationI[]>([])
  const [state, setState] = useState({
    showEdit: false,
  })
  const [user, setUser] = useState(null)

  const { t } = useTranslation("global")

  useEffect(() => {
    setUser(parsedUser())
    requestOrganizations()
  }, [])

  const requestOrganizations = async () => {
    let theUser = parsedUser()
    if (theUser === null) {
      return
    }
    let requestAPI = await fetch(
      `https://service.plnify.com/api/Organizations/GetOrganizations?AccountGUID=${theUser.ID}&source=web`
    )
    let response: APIResponse = await requestAPI.json()
    if (response.status === "ok") {
      const cleanOrganizations = response.response.filter(
        item => !item.Deleted && !item.Organization.Deleted
      )
      setOrganizations(cleanOrganizations)
    }
  }

  const changeTheOrganization = async (organization: OrganizationI) => {
    localStorage.setItem("CURRENT_ORGANIZATION", JSON.stringify(organization))
    history.push(Routes.Home.path)
  }

  const toggleAddEdit = async reload => {
    setState({
      ...state,
      showEdit: !state.showEdit,
    })
    if (reload) {
      await requestOrganizations()
    }
  }
  useEffect(() => {
    if (!state.showEdit) {
      requestOrganizations()
    }
  }, [state.showEdit])

  return (
    <main>
      {state.showEdit ? (
        <AddEditOrganization show={state.showEdit} toggle={toggleAddEdit} />
      ) : null}
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light px-4 px-lg-5 py-3 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">{t("select_organization")}</h5>
                  <hr />
                </div>
                {organizations && organizations.length > 0 ? (
                  organizations.map((item, index) => (
                    <Row key={item.GUID}>
                      <div className="col-auto">
                        <img
                          src={userAvatar(item.Organization.Name, 40)}
                          className="img-fluid rounded"
                        />
                      </div>
                      <Col
                        className="clickable "
                        onClick={() => {
                          changeTheOrganization(item)
                        }}
                      >
                        <span>{item.Organization.Name}</span>
                        {/* <br />
                        <small className="text-muted">
                          {item.creatorName} ({item.creatorEmail})
                        </small> */}
                      </Col>
                      <Col xs={12}>
                        <hr />
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span>{t("no_organizations")}</span>
                  </Col>
                )}
                <Row>
                  <Col className="text-center mt-4" xs={12}>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => toggleAddEdit(false)}
                    >
                      {t("create_organization")}
                    </button>
                    <br />
                    <button
                      className="btn btn-link"
                      onClick={() => {
                        props.history.push(Routes.SignIn.path)
                      }}
                    >
                      {t("logout")}
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default SelectOrganization
