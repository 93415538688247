import { SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { Id } from "../../interfaces/column.interface"
import { Runs } from "../../interfaces/runs.interface"
import { TaskInterface } from "../../interfaces/task.interface"
import { TaskCard } from "./TaskCard"
import API from "../../common/API"
import { useFormik } from "formik"
import moment from "moment"
import { vehicleTypes } from "./RunnersDTO"

interface Props {
  column: Runs
  deleteColumn: (id: Id) => void
  updateColumn: (payload: Runs) => void
  createTask: (id: Id, title: string, content: string) => void
  updateTask: (id: Id, title: string, content: string) => void
  deleteTask: (id: Id) => void
  setTasks: Dispatch<SetStateAction<TaskInterface[]>>
  setCurrentColumn: Dispatch<SetStateAction<Runs | null>>
  toggleShareModal: () => void
  getTask: (columnId: string) => void
  tasks: TaskInterface[]
  showFilter?: boolean
  taskEditMode: { id: Id; isEditMode: boolean }
  setTaskEditMode: Dispatch<SetStateAction<{
    id: Id
    isEditMode: boolean
  }>>
}

export function ColumnContainer({
  column,
  deleteColumn,
  updateColumn,
  createTask,
  setCurrentColumn,
  toggleShareModal,
  tasks,
  taskEditMode,
  setTaskEditMode,
  setTasks,
  deleteTask,
  updateTask,
  getTask,
  showFilter,
}: Props) {
  useEffect(() => {
    getTask(column.id)
  }, [])

  const [editMode, setEditMode] = useState(false)

  const tasksIds = useMemo(() => {
    return tasks.map(task => task.id) as [string]
  }, [tasks])

  const isEditMode = taskEditMode.isEditMode || editMode
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
    disabled: isEditMode,
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  const handleSubmit = async (values: any) => {
    try {
      const payload: Runs = {
        id: column.id,
        eventGUID: column.eventGUID,
        startTime: moment(values.startTime).format(),
        endTime: moment(values.endTime).format(),
        capacity: values.capacity,
        floatAmount: values.floatAmount,
        vehicleType: values.vehicleType,
        runner: {
          id: column.runner.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: column.runner.email,
          mobilePhone: values.mobilePhone,
        },
        order: column.order,
      }

      const response = await API.postAction(`Run/AddEdit`, payload)

      if (response.data.status === "ok") {
        updateColumn(payload)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setEditMode(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: column.runner.firstName,
      lastName: column.runner.lastName,
      mobilePhone: column.runner.mobilePhone,
      startTime: column.startTime,
      endTime: column.endTime,
      capacity: column.capacity,
      vehicleType: column.vehicleType,
      floatAmount: column.floatAmount,
    },
    onSubmit: handleSubmit,
  })

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={{ ...style }}
        className={`column-dragging`}
      ></div>
    )
  }

  return (
    <div
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      className={`column-container p-2 border border-2`}
    >
      {/* Column title */}
      <div
        className="d-flex align-align-items-end justify-content-between w-100"
        style={{
          borderRadius: "100%",
        }}
      >
        <span className="column-header bg-primary">{`Runner #${column.order + 1}`}</span>
        <div className="d-flex align-items-center" style={{ gap: "0.3rem" }}>
          <button
            onClick={() => {
              setCurrentColumn(column)
              toggleShareModal()
            }}
            className="btn btn-primary px-2 py-1 "
            style={{ fontSize: "12px", gap: "0.3rem" }}
          >
            <span className="">Share</span>
            <i
              className="fas fa-share-alt"
              style={{
                marginLeft: "0.3rem",
              }}
            ></i>
          </button>
          <button
            onClick={() => {
              deleteColumn(column.id)
            }}
            className="btn btn-danger px-2 py-1 "
            style={{ fontSize: "12px", gap: "0.3rem" }}
          >
            {/* <span className="">Delete</span> */}
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <div className="column-task-container">
        {!editMode && (
          <div className="column-detail-container border-primary">
            <div className="column-detail border-0 position-relative w-100">
              <span
                className="column-edit-btn btn btn-primary btn-sm position-absolute right-2 top-2"
                onClick={() => {
                  setEditMode(true)
                }}
              >
                <i className="fas fa-edit"></i>
              </span>
              <div
                className="row w-100 d-flex"
                style={{
                  gap: "0.4rem",
                }}
              >
                <div className="col-12 row">
                  <div className="position-relative col-6">
                    <span
                      className="position-absolute text-black-50"
                      style={{
                        top: "-8px",
                        left: "10px",
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      First Name:
                    </span>
                    <span
                      className="text-truncate "
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {column.runner.firstName}
                    </span>
                  </div>
                  <div className="position-relative col-6">
                    <span
                      className="position-absolute text-black-50"
                      style={{
                        top: "-8px",
                        left: "10px",
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Last Name:
                    </span>
                    <span
                      className="col-3 text-truncate"
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {column.runner.lastName}
                    </span>
                  </div>
                </div>

                <div className="col-12 row mt-2">
                  <div className="position-relative col-6">
                    <span
                      className="position-absolute text-black-50"
                      style={{
                        top: "-8px",
                        left: "10px",
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Contact:
                    </span>
                    <span
                      className="col-3 text-truncate"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {column.runner.mobilePhone}
                    </span>
                  </div>
                  <div className="position-relative col-6">
                    <span
                      className="position-absolute text-black-50"
                      style={{
                        top: "-8px",
                        left: "10px",
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Vehicle Type:
                    </span>
                    <span
                      className="col-3 text-truncate"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {column.vehicleType}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="position-relative d-flex flex-column "
              style={{
                gap: "0.4rem",
                fontSize: "12px",
                padding: ".5rem 2rem",
              }}
            >
              <div className="runner-details-container">
                <div className="d-flex flex w-100 position-relative">
                  <span
                    className="position-absolute text-black-50"
                    style={{
                      top: "-10px",
                      left: "0",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Start Time:
                  </span>
                  <span>{new Date(column.startTime).toLocaleTimeString()}</span>
                </div>
                <div className="d-flex flex w-100 position-relative">
                  <span
                    className="position-absolute text-black-50"
                    style={{
                      top: "-10px",
                      left: "0",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Cut Time:
                  </span>
                  <span>{new Date(column.endTime).toLocaleTimeString()}</span>
                </div>
                <div className="d-flex flex w-100 position-relative">
                  <span
                    className="position-absolute text-black-50"
                    style={{
                      top: "-10px",
                      left: "0",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Cap:
                  </span>
                  <span>{column.capacity}</span>
                </div>
              </div>
              <span className="badge bg-primary">Float Amount: {column.floatAmount}</span>
            </div>
          </div>
        )}

        {editMode && (
          <form
            onSubmit={formik.handleSubmit}
            className="column-detail-container"
          >
            <div
              className="column-detail border-0 d-flex flex-column"
              style={{
                gap: "1rem",
              }}
            >
              <div className="col-12 row">
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="firstName"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    First Name
                  </label>
                  <input
                    className="column-title-input m-0"
                    name="firstName"
                    type="text"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    autoFocus
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="lastName"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Last Name
                  </label>
                  <input
                    className="column-title-input m-0"
                    name="lastName"
                    type="text"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
              </div>
              <div className="col-12 row">
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="mobilePhone"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Contact
                  </label>
                  <input
                    type="number"
                    className="column-title-input m-0"
                    name="mobilePhone"
                    value={Number(formik.values.mobilePhone)}
                    onChange={formik.handleChange}
                    autoFocus
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="vehicleType"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Vehicle Type
                  </label>

                  <select
                    className="column-title-input m-0"
                    style={{
                      width: "92%",
                    }}
                    name="vehicleType"
                    value={formik.values.vehicleType}
                    onChange={formik.handleChange}
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  >
                    {vehicleTypes.map((item, index) => {
                      return (
                        <option key={index + item.id} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="col-12 row">
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="startTime"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Start Time
                  </label>
                  <input
                    className="column-title-input m-0"
                    style={{
                      width: "92%",
                    }}
                    name="startTime"
                    type="datetime-local"
                    value={moment(formik.values.startTime).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}
                    onChange={formik.handleChange}
                    autoFocus
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
                <div className="position-relative col-6 p-0 ">
                  <label
                    htmlFor="endTime"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Cut Time
                  </label>
                  <input
                    style={{
                      width: "92%",
                    }}
                    className="column-title-input m-0 max-w-100"
                    type="datetime-local"
                    name="endTime"
                    value={moment(formik.values.endTime).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}
                    onChange={formik.handleChange}
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
              </div>
              <div className="col-12 row">
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="capacity"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Cap
                  </label>
                  <input
                    className="column-title-input m-0"
                    name="capacity"
                    value={formik.values.capacity}
                    onChange={formik.handleChange}
                    autoFocus
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
                <div className="position-relative col-6 p-0">
                  <label
                    htmlFor="floatAmount"
                    className="position-absolute text-black-50 bg-white"
                    style={{
                      top: "-9px",
                      left: "4px",
                      padding: "2px",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Float Amount
                  </label>
                  <input
                    className="column-title-input m-0"
                    name="floatAmount"
                    value={formik.values.floatAmount}
                    onChange={formik.handleChange}
                    onKeyDown={e => {
                      if (e.key !== "Enter") return
                      setEditMode(false)
                    }}
                  />
                </div>
              </div>
              <div className="col-12 row">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    fontSize: "12px",
                    gap: "0.3rem",
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setEditMode(false)
                  }}
                  className="btn btn-danger mt-2"
                  style={{
                    fontSize: "12px",
                    gap: "0.3rem",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      <div
        className="d-flex align-items-end justify-content-between w-100 mt-2"
        style={{
          borderRadius: "100%",
        }}
      >
        <span className="run-badge bg-secondary">Runs ({tasks.length})</span>

      </div>

      {/* Column task container */}
      <div
        className="task-main-container border-secondary"
        style={{
          minHeight: showFilter
            ? "calc(100dvh - 630px)"
            : "calc(100dvh - 450px)",
          maxHeight: showFilter
            ? "calc(100dvh - 630px)"
            : "calc(100dvh - 450px)",
          // maxHeight: "300px",
          overflowY: "auto",
          padding: "5px 5px",
        }}
      >
        {tasks.length === 0 && (
          <div className="text-center text-gray-500">No tasks yet</div>
        )}
        <SortableContext items={tasksIds}>
          {tasks.map(task => (
            <TaskCard
              key={task.id}
              task={task}
              taskEditMode={taskEditMode}
              setTaskEditMode={setTaskEditMode}
              deleteTask={deleteTask}
              updateTask={updateTask}
              getTask={() => getTask(column.id)}
            />
          ))}
        </SortableContext>
      </div>
      {/* Column footer */}
      <button
        className="btn btn-primary w-100 d-flex gap-1 justify-content-center align-items-center"
        onClick={() => {
          createTask(column.id, "", "")
        }}
      >
        <i className="fas fa-plus"></i>
        Add task
      </button>
    </div>
  )
}
