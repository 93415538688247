import SettingSectionDTO from "./SettingSectionDTO";

const SettingSetions : SettingSectionDTO[]= [
	{
		name: "administration",
		parentId: "admin",
		requiredParent: false,
		sections: [
			{
				reference: "users",
				label: "users",
			},
			{
				reference: "roles",
				label: "roles",
			},
			
		]
	},

	{
		name: "Parameters",
		parentId: "admin",
		requiredParent: false,
		sections: [
			
			{
				reference: "countries",
				label: "Countries",
			},
			{
				reference: "earTypes",
				label: "Ear Types",
			},
			{
				reference: "eyeTypes",
				label: "Eye Types",
			},
			{
				reference: "hairType",
				label: "Hair Types",
			},
			{
				reference: "height",
				label: "Heights",
			},
			{
				reference: "mouthTypes",
				label: "Mouth Types",
			},
			{
				reference: "noseType",
				label: "Nose Types",
			},
			{
				reference: "nationality",
				label: "Nationality",
			},
		]
	},
	
]

export default SettingSetions;