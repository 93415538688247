//We are settings an initial state here for clarity, probably is not needed

const Settings = {
  App: "plnify-runners",
  Name: "plnify-runners",

  baseURl: "./",
  BasePathForAPI: "https://api.runners.plnify.com/api/",
  BasePath: "https://api.runners.plnify.com/",
  AppURL: "https://runners.plnify.com/",
  // BasePathForAPI: "https://localhost:44322/api/",
  // BasePath: "https://localhost:44322/",

  SocketPath: "",
  SyncFusionKey: ""
}

export default Settings


