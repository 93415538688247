

import React from "react";
import AdminUsersComponent from "../components/Admin/AdminUsersComponent";




const AdminUsersPage = () => {
    return (<AdminUsersComponent />)
}

export default AdminUsersPage;