import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import { ToastNotificationSuccess } from "./Notifications/ToastNotification"
import { Modal } from "react-bootstrap"
import Swal from "sweetalert2"
import * as uuid from "uuid"

const SharingComponent = props => {
  const [state, setState] = useState({
    UI: {
      Current: props.Item,
      ShareViaEmail: false,
      ShareViaLink: false,
    },
    AccountGUID: "",
  })

  const basicInfo = parsedUser()

  useEffect(() => {
    setState(prev => ({
      ...prev,
      AccountGUID: basicInfo.GUID,
    }))
  }, [])

  const generateLink = async (type: string, sectionId: string) => {
    const currentUser = parsedUser()
    const currentOrganization = JSON.parse(
      localStorage.getItem("CURRENT_ORGANIZATION") ?? ""
    )

    const token = localStorage.getItem("LogedUser")

    const request = await fetch(
      "https://service.plnify.com/api/collab/GenerateLink",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          GUID: uuid.v4(),
          SectionType: type,
          SectionGUID: sectionId, //example id of the todo
          SharedByAccountGUID: currentUser.ID, // id of the user who shared this
          OrganizationGUID: currentOrganization.GUID, //id of the organization
          ParentSectionGUID: null, //sent it null
          Deleted: null, //sent it null
          Updated: null, //sent it null
          Created: null, //sent it null
          PermissionType: 1, // 0 = readonly ; 1= read and write
        }),
      }
    )

    const response = await request.json()

    return response.response.GUID
  }

  const toggleShareEmail = () => {
    let processTheRecord = ""
    let title = ""
    switch (props.Type) {
      case "MOREDETAILS":
        break
      case "NOTE":
        title = "Note"
        processTheRecord = props.Item.Details

        setState(prev => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }))

        break
      case "SHARECOLLAB":
        setState(prev => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: props?.shareApi,
              Title: "Create your Ticket Request",
            },
          },
        }))

        break
      case "CONTACT":
        title = "Contact"
        if (props.Item.Name !== undefined && props.Item.Name !== null) {
          let name =
            props.Item.Name +
            " " +
            (props.Item.LastName ? props.Item.LastName : "")
          title = name + " " + title
          processTheRecord += name
        }
        if (props.Item.Email) {
          processTheRecord += "<br/><b>Email: </b>" + props.Item.Email
        }
        if (props.Item.MobilePhone) {
          processTheRecord += "<br/><b>Mobile: </b>" + props.Item.MobilePhone
        }
        if (props.Item.OfficePhone) {
          processTheRecord +=
            "<br/><b>Office: </b>" +
            props.Item.OfficePhone +
            (props.Item.Extension ? " ext. " + props.Item.Extension : "")
        }
        if (props.Item.Position) {
          processTheRecord += "<br/><b>Position: </b>" + props.Item.Position
        }

        setState(prev => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }))
        break

      default:
        break
    }
    setState(prev => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShareViaEmail: !prev.UI.ShareViaEmail,
      },
    }))
  }
  const toggleShareLink = () => {
    switch (props.Type) {
      case "MOREDETAILS":
        break
      case "CONTACT":
        break
      default:
        break
    }

    setState(prev => ({
      ...prev,
      UI: {
        ...prev.UI,
        ShareViaEmail: !prev.UI.ShareViaEmail,
      },
    }))
  }

  const copyToClipboard = async () => {
    let processTheRecord = ""
    let title = ""

    switch (props.type) {
      case "run":
        console.log({ item: props.item })
        title = "Run"
        processTheRecord = `${window.location}r/${await generateLink(
          "run",
          props.item.id
        )}` //`${window.location}r/${props.item.id}/e/${props.item.eventGUID}`
        setState(prev => ({
          ...prev,
          UI: {
            ...prev.UI,
            Current: {
              ...prev.UI.Current,
              Content: processTheRecord,
              Title: title,
            },
          },
        }))
        break
      default:
        break
    }
    navigator.clipboard.writeText(processTheRecord)
    Swal.fire({
      title: "Copied!",
      text: "The link has been copied to your clipboard",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
    })
    props.toggle()
  }
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <div
        className="event-page p-4"
        style={{ overflow: "revert", zIndex: "100" }}
      >
        <div className="event-expennd-section">
          <div className="text-head">
            <div className="text-left d-flex w-100 justify-content-between align-items-center mb-4">
              <h5 className="title">Share Options</h5>
              <button
                type="button"
                className="btn btn-danger px-2 py-1"
                onClick={() => props.toggle()}
              >
                <FontAwesomeIcon icon={faTimes} color="white" />
              </button>
            </div>
          </div>
          <div className="body text-dark p-2">
            <div className="row align-items-center justify-content-center">
              {props.ViaEmail ? (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={() => {
                      toggleShareEmail()
                    }}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-paper-plane" />
                      <br />
                      E-Mail
                    </div>
                  </div>
                </div>
              ) : null}

              {props.ViaLink && (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={() => {
                      toggleShareLink()
                    }}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-link" />
                      <br />
                      Link
                    </div>
                  </div>
                </div>
              )}

              {props.ViaClipboard ? (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={
                      props.copyToClipBoard
                        ? () => props.copyToClipBoard()
                        : () => {
                            copyToClipboard()
                          }
                    }
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-copy" />
                      <br />
                      Copy
                    </div>
                  </div>
                </div>
              ) : null}

              {props.ViaSMS && (
                <div className="col-4">
                  <div
                    className="clickable card shadow-sm"
                    onClick={() => {
                      Swal.fire({
                        title: "SMS",
                        text: "This feature is not yet available",
                        icon: "info",
                      })
                      props.toggle()
                    }}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-sms" />
                      <br />
                      SMS
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SharingComponent
