import { useFormik } from "formik"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import FieldSelector from "../core/FieldSelector"
import CRUDField from "../core/genericCRUD/CRUDField"
import RunnersDTO from "./RunnersDTO"
import * as uuid from "uuid"
import API from "../../common/API"
import moment from "moment"
import { createRunnerSchema } from "../../helpers/validationSchemas"
import { set } from "lodash"
import Swal from "sweetalert2"

const AddRunnerModal = props => {
  const fields: CRUDField[] = RunnersDTO
  const { t } = useTranslation("global")
  const formikInitialObject = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    startTime: "",
    cutTime: "",
    cap: "",
    floatAmount: "",
    vehicleType: "",
  }

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: createRunnerSchema,
    onSubmit: () => handleSubmit(),
  })
  const updateField = (fieldName: string, fieldValue: any) => {
    formik.setFieldValue(fieldName, fieldValue)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const organization = JSON.parse(
      localStorage.getItem("CURRENT_ORGANIZATION") ?? ""
    )
    const payload = {
      id: uuid.v4(),
      eventGUID: props.selectedEvent,
      startTime: moment(
        new Date(
          `${moment(props?.event?.Date).format("YYYY-MM-DD")}T${
            formik.values.startTime
          }`
        )
      ).format(),
      endTime: moment(
        new Date(
          `${moment(props?.event?.Date).format("YYYY-MM-DD")}T${
            formik.values.cutTime
          }`
        )
      ).format(),
      capacity: Number(formik.values.cap) || 0,
      floatAmount: Number(formik.values.floatAmount) || 0,
      vehicleType: formik.values.vehicleType || null,
      runner: {
        id: uuid.v4(),
        firstName: formik.values.firstName || null,
        lastName: formik.values.lastName || null,
        email: formik.values.email || null,
        mobilePhone: formik.values.contactNumber || null,
        organizationId: organization.OrganizationGUID || null,
      },
      order: Number(props.runsQuantity) || 0,
    }

    try {
      if (Object.keys(props.selectedEvent).length === 0) {
        setIsLoading(false)
        Swal.fire({
          title: "Error!",
          text: "Please select an event and try again",
          icon: "error",
          confirmButtonText: "Ok",
        })
        throw new Error("Event is required")
      }
      const response = await API.postAction("Run/AddEdit", payload)
      props.getEvents()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      props.toggle()
    }
  }

  return (
    <Modal
      show={true}
      onHide={props.toggle}
      size={props.modalSize ? props.modalSize : "lg"}
      className={props.cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex gap-2 flex-col px-4">
          <div className="row">
            {fields &&
              fields
                .filter(x => x.htmlType !== "hidden")
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={item}
                      updateField={updateField}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  )
                })}
          </div>
          {formik.values.id && props.childrens && props.childrens.length > 0 ? (
            <div className="grid gri-cols-1 gap-2	">
              <h2 className="text-3xl font-bold dark:text-white">
                {t("subSections")}
              </h2>
              <div className="tabs tabs-boxed ">
                {props.childrens.map((item, index) => {
                  const parentId: string = formik.values && formik.values["id"]
                  return (
                    <>
                      <input
                        type="radio"
                        name={item.id}
                        role="tab"
                        className="tab"
                        aria-label={item.name}
                        checked={index === 0}
                        id={item.id}
                        //onChange={() => {}}
                      />

                      <div
                        role="tabpanel"
                        className="tab-content  bg-slate-100"
                      >
                        {item.component({ parentId: parentId })}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Body>

      <Modal.Footer>
        {/* {formik.errors? <div className="text-red-600">{console.log(formik.errors)}</div> :null} */}
        <div className="modal-action m-0 p-2 ">
          {isLoading && (
            <div className="me-4 d-inline-block">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <button
            className="btn btn-secondary me-2"
            disabled={isLoading}
            onClick={() => props.toggle()}
            type="button"
          >
            {t("Cancel")}
          </button>
          {/* if there is a button in form, it will close the modal */}
          <button
            className="btn btn-primary text-white"
            disabled={isLoading}
            onClick={() => formik.submitForm()}
            type="button"
          >
            {t("Save")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddRunnerModal
