import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const AdminUsersComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      isModal={false}
      modalSize="sm"
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Users") ?? ""}
      icon={"fas fa-users"}
      getRecordsPath={`users/AdminUsers`}
      addEditRecordPath={`users/AddEditAdminUsers?currentUserId=${user.ID}`}
      deleteRecordPath="expense/DeleteExpenseType"
      filterFields={[
        {
          name: "search",
          htmlType: "text",
          dbType: "string",
          title: t("search") ?? "",
          containerClass: "col-md-4 col-12",
          required: true,
        },
      ]}
      fieldsList={[
        {
          name: "firstName",
          title: t("Name"),
        },
        {
          name: "lastName",
          title: t("Last Name"),
        },
        {
          name: "email",
          title: t("email"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "firstName",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "lastName",
          htmlType: "text",
          dbType: "string",
          title: t("Last Name") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "email",
          htmlType: "email",
          dbType: "string",
          title: t("email") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "password",
          htmlType: "password",
          dbType: "string",
          title: t("Password") ?? "",
          containerClass: "col-12",
          required: true,
        },
      ]}
    />
  )
}

export default AdminUsersComponent
