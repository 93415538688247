

import React from "react";
import Register from "../components/Profile/Register";



const RegisterPage = () => {
    return (<Register />)
}

export default RegisterPage;